import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Editor = ({ codeLines, highlightedLine, highlightedMultipleLines, focusedEndLine, topic, callingLine, secondCallingLine, thirdCallingLine, subTopicId }) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [copyStatus, setCopyStatus] = useState('Copy');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalMessageType, setModalMessageType] = useState('Success');
  const [loading, setLoading] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (!token) return;

    const url = `${apiUrl}/api/user-progress/${subTopicId}/`;
    axios.get(url, {
      headers: {
        Authorization: `Token ${token}`,
      }
    })
    .then(response => {
      setIsCompleted(response.data.is_completed);
    })
    .catch(error => {
      console.error('Error fetching completion status:', error);
    });
  }, [subTopicId, apiUrl]);

  const handleCopy = () => {
    const editorContent = document.getElementById('editor').innerText;
    const cleanedCode = editorContent
    .split('\n')
    .filter(element => !/^\d+$/.test(element))
    .slice(0, -1)
    .join('\n');
    
    navigator.clipboard.writeText(cleanedCode).then(() => {
      setCopyStatus('Copied!');
      setTimeout(() => setCopyStatus('Copy'), 2000);
    });
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleToggle = async () => {
    setModalMessage('');
    setLoading(true);
    setShowModal(true);
    await delay(200);

    const token = localStorage.getItem('userToken');
    if (!token) {
      setLoading(false);
      setModalMessageType('Fail');
      setModalMessage('VIP access only—log in to unlock the magic!');
      return;
    }

    const url = `${apiUrl}/api/user-progress/${subTopicId}/toggle-complete/`;
    axios.post(url, {}, {
      headers: {
        Authorization: `Token ${token}`,
      }
    })
    .then(response => {
      setModalMessage(response.data.message);
      setModalMessageType('Success')
      setLoading(false);
      setIsCompleted(prevState => !prevState);
    })
    .catch(error => {
      setLoading(false);
      setModalMessageType('Fail');
      setModalMessage('Try Again!');
    });
  };

  return (
    <div className="editor-card shadow-lg p-3 position-relative">
      <button 
        className={`btn btn-lg toggle-btn position-absolute ${isCompleted ? 'btn-toggle-on' : 'btn-toggle-off'}`} 
        style={{right: 0}}
        onClick={handleToggle}
      >
        <div className="toggle-switch">
          <div className={`toggle-knob ${isCompleted ? 'toggle-knob-on' : 'toggle-knob-off'}`}></div>
        </div>
        <span className="toggle-label">{isCompleted ? 'Done' : 'Done?'}</span>
      </button>

      <h3 className="section-title">Code</h3>
      <h5 style={{fontWeight: 'bold'}}>{topic}</h5>
      <pre id="editor" className="position-relative">
        {codeLines.map((line, index) => (
          <div
            style={{ padding: '0px' }}
            key={index}
            className="pre-div code-line"
          >
            <span className="line-number">{index + 1}</span>
            <span className={`code-content
              ${highlightedLine === index && !focusedEndLine ? 'highlight' : ''}
              ${highlightedMultipleLines && highlightedMultipleLines.includes(index) ? 'highlight' : ''}
              ${callingLine === index || secondCallingLine === index || thirdCallingLine === index ? 'calling-highlight' : ''}`}
            >
              {line}
            </span>
          </div>
        ))}
        <button 
          onClick={handleCopy} 
          id="copyBtn" 
          className="btn btn-dark btn-sm copy-btn position-absolute" 
          style={{ top: '10px', right: '10px' }}
        >
          <i className="fa fa-copy"></i> {copyStatus}
        </button>
      </pre>

      {/* Modal */}
      {showModal && (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modal-glass-avatar">
              <div className="modal-header"></div>
              <div className="modal-body">
                {loading ? (
                  <div className="text-center my-2">
                    <div className="spinner-border" role="status"></div>
                  </div>
                ) : (
                  <div className={`user-progress-animation-container ${modalMessageType === 'Fail' ? 'user-progress-failure' : 'user-progress-success'}`}>
                    <div className="user-progress-circle">
                      {modalMessageType === 'Fail' ? (
                        <i className="fas fa-times-circle user-progress-cross-mark"></i>
                      ) : (
                        <i className="fas fa-check-circle user-progress-tick-mark"></i>
                      )}
                    </div>
                    <p className={`${modalMessageType === 'Fail' ? 'user-progress-fail-message' : 'user-progress-success-message'}`}>{modalMessage}</p>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                {/* {message && <p className="text-center mt-2">{message}</p>} */}
                <button type="button" className="btn user-progress-close-btn" onClick={() => {
                  setModalMessage('');
                  setShowModal(false);
                }}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Editor;
