import { useState, useEffect, useRef } from 'react';
import explanations from './explanations';

export const useDictionaryTypes = (language, student) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [highlightedMultipleLines, setHighlightedMultipleLines] = useState([]);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [mapVariables, setmapVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedMapVariables, setHighlightedMapVariables] = useState([]);
  const [highlightedMapIndex, setHighlightedMapIndex] = useState([]);
  const [isMuted, setIsMuted] = useState(true);
  const isMutedRef = useRef(isMuted);
  const synth = window.speechSynthesis;

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setHighlightedMultipleLines([]);
    setFocusedEndLine(null);
    setVariables({});
    setArrayVariables({});
    setmapVariables({});
    setLogs([]);
    setHighlightedVariables([]);
    setHighlightedMapVariables([]);
  };

  const speak = (message) => {
    return new Promise((resolve) => {
      if (isMutedRef.current) {
        resolve();
        return;
      }
      const utterance = new SpeechSynthesisUtterance(message);
      utterance.onend = resolve;
      synth.speak(utterance);
    });
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const highlightMultipleLines = async (lineNumbers) => {
    setHighlightedMultipleLines(lineNumbers);
    let lineNumber = lineNumbers[0];
    
    if (lineNumber) {
      await logMessage(`Line ${lineNumber + 1}: ${explanations['Python'][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
    }
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async () => {
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runDictionaryTypesPython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightMultipleLines([2, 3, 4, 5, 6]);
    setmapVariables({ student: { variable_name: 'student', value: student } });
    setHighlightedMapVariables(['student']);
    await customSleep();
    setHighlightedMapVariables([]);
    highlightMultipleLines([]);

    await highlightLine(9);
    setHighlightedMapIndex([{ mapName: 'student', key: 'name' }]);
    await logMessage(`Name: ${student.name}`);
    await customSleep();
    setHighlightedMapIndex([]);

    await highlightLine(10);
    setHighlightedMapIndex([{ mapName: 'student', key: 'age' }]);
    await logMessage(`Age: ${student.age}`);
    await customSleep();
    setHighlightedMapIndex([]);

    await highlightLine(11);
    setHighlightedMapIndex([{ mapName: 'student', key: 'graduated' }]);
    await logMessage(`Graduated: ${student.graduated}`);
    await customSleep();
    setHighlightedMapIndex([]);

    await highlightLine(14);
    student["GPA"] = 3.8;
    setHighlightedMapIndex([{ mapName: 'student', key: 'GPA' }]);
    await customSleep();
    setHighlightedMapIndex([]);

    await highlightLine(17);
    setHighlightedMapIndex([{ mapName: 'student', key: 'age' }]);
    student["age"] = 23;
    await customSleep();
    setHighlightedMapIndex([]);

    await highlightLine(20);
    setHighlightedMapIndex([{ mapName: 'student', key: 'graduated' }]);
    delete student["graduated"];
    await customSleep();
    setHighlightedMapIndex([]);

    await highlightLine(23);
    await logMessage("Updated student dictionary:");
    await customSleep();

    for (const [key, value] of Object.entries(student)) {
        await highlightLine(24);
        setHighlightedMapIndex([{ mapName: 'student', key: key }]);
        setVariables((vars) => ({ ...vars, key: { variable_name: 'key', value: key }, value: { variable_name: 'value', value: value } }));
        setHighlightedVariables(['key', 'value']);
        await customSleep();
        setHighlightedMapIndex([]);

        await highlightLine(25);
        await logMessage(`${key}: ${value}`);
        await customSleep();
        setHighlightedVariables([]);
    }

    await focusEndLine(25);
    setIsRunning(false);
  };

  const runDictionaryTypesJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    let _student = {};

    await highlightLine(5);
    setmapVariables({ student: { variable_name: 'student', value: _student } });
    setHighlightedMapVariables(['student']);
    await customSleep();
    setHighlightedMapVariables([]);

    await highlightLine(6);
    _student['name'] = student['name'];
    setHighlightedMapIndex([{ mapName: 'student', key: 'name' }]);
    await customSleep();
    setHighlightedMapIndex([]);

    await highlightLine(7);
    _student['age'] = student['age'];
    setHighlightedMapIndex([{ mapName: 'student', key: 'age' }]);
    await customSleep();
    setHighlightedMapIndex([]);

    await highlightLine(8);
    _student['graduated'] = student['graduated'];
    setHighlightedMapIndex([{ mapName: 'student', key: 'graduated' }]);
    await customSleep();
    setHighlightedMapIndex([]);

    await highlightLine(11);
    setHighlightedMapIndex([{ mapName: 'student', key: 'name' }]);
    await logMessage(`Name: ${_student.name}`);
    await customSleep();
    setHighlightedMapIndex([]);

    await highlightLine(12);
    setHighlightedMapIndex([{ mapName: 'student', key: 'age' }]);
    await logMessage(`Age: ${_student.age}`);
    await customSleep();
    setHighlightedMapIndex([]);

    await highlightLine(13);
    setHighlightedMapIndex([{ mapName: 'student', key: 'graduated' }]);
    await logMessage(`Graduated: ${_student.graduated}`);
    await customSleep();
    setHighlightedMapIndex([]);

    await highlightLine(16);
    _student["GPA"] = 3.8;
    setHighlightedMapIndex([{ mapName: 'student', key: 'GPA' }]);
    await customSleep();
    setHighlightedMapIndex([]);

    await highlightLine(19);
    setHighlightedMapIndex([{ mapName: 'student', key: 'age' }]);
    _student["age"] = 23;
    await customSleep();
    setHighlightedMapIndex([]);

    await highlightLine(22);
    setHighlightedMapIndex([{ mapName: 'student', key: 'graduated' }]);
    delete _student["graduated"];
    await customSleep();
    setHighlightedMapIndex([]);

    await highlightLine(25);
    await logMessage("Updated student dictionary:");
    await customSleep();

    for (const [key, value] of Object.entries(_student)) {
        await highlightLine(26);
        setHighlightedMapIndex([{ mapName: 'student', key: key }]);
        let entry = {
          [key]: value
        }
        setmapVariables((vars) => ({ ...vars, entry: { variable_name: 'entry', value: entry } }));
        setHighlightedMapVariables(['entry']);
        await customSleep();
        setHighlightedMapIndex([]);
        setHighlightedMapVariables([]);

        await highlightLine(27);
        setHighlightedMapIndex([{ mapName: 'entry', key: key }]);
        await logMessage(`${key}: ${value}`);
        await customSleep();
        setHighlightedVariables([]);
        setHighlightedMapIndex([]);
    }

    await focusEndLine(25);
    setIsRunning(false);
  };

  const runDictionaryTypes = async () => {
    if (language === 'Python') {
      await runDictionaryTypesPython();
    } else if (language === 'Java') {
      await runDictionaryTypesJava();
    }
  };

  return {
    highlightedLine,
    highlightedMultipleLines,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    mapVariables,
    highlightedVariables,
    highlightedMapVariables,
    highlightedMapIndex,
    isMuted,
    setHighlightedLine,
    setHighlightedMultipleLines,
    setFocusedEndLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setmapVariables,
    setHighlightedVariables,
    setHighlightedMapVariables,
    setHighlightedMapIndex,
    setIsMuted,
    runDictionaryTypes
  };
};
