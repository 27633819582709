const generateMemoryAddress = () => {
  return '0x' + Math.floor(Math.random() * 0xFFF).toString(16);
};

class Node {
  constructor(data, nodeAddress) {
    this.data = data;
    this.next = null;
    this.address = nodeAddress;
  }
}

class GraphForPython {
  constructor() {
    this.graph = new Map();
    this.address = {};
  }

  async addEdge(
    vertex,
    neighbor,
    directed,
    setGraphVariables,
    setmapVariables
  ) {
    
    if (!this.graph.has(vertex)) {
      this.graph.set(vertex, []);

      this.address[vertex] = generateMemoryAddress();
      setGraphVariables((vars) => ({ ...vars, 
        [vertex]: { 
          variable_name: vertex,
          address: this.address[vertex],
          edges: []
        }
      }));
      let _graph = {};
      for (let [vertex, neighbors] of this.graph.entries()) {
        _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
      }
      setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
    }

    if (!this.graph.has(neighbor)) {
      this.graph.set(neighbor, []);

      this.address[neighbor] = generateMemoryAddress();
      setGraphVariables((vars) => ({ ...vars, 
        [neighbor]: { 
          variable_name: neighbor,
          address: this.address[neighbor],
          edges: []
        }
      }));
      let _graph = {};
      for (let [vertex, neighbors] of this.graph.entries()) {
        _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
      }
      setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
    }

    this.graph.get(vertex).push(neighbor);
    let _graph = {};
    for (let [vertex, neighbors] of this.graph.entries()) {
      _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
    }
    
    setGraphVariables((vars) => ({ ...vars, 
      [vertex]: { 
        variable_name: vertex,
        address: this.address[vertex],
        edges: this.graph.get(vertex)
      }
    }));
    
    if (!directed) {
      this.graph.get(neighbor).push(vertex);
      let _graph = {};
      for (let [vertex, neighbors] of this.graph.entries()) {
        _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
      }
      
      setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
      setGraphVariables((vars) => ({ ...vars, 
        [neighbor]: { 
          variable_name: neighbor,
          address: this.address[neighbor],
          edges: this.graph.get(neighbor)
        }
      }));
    }
  }

  async bfs(
    start,
    highlightLine,
    setVariables,
    setHighlightedVariables,
    setHighlightedGraphVariables,
    setSetsVariables,
    setHighlightedMapIndex,
    setHighlightedSetIndex,
    setHighlightedSetVariables,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    logMessage,
    customSleep
  ) {
    if (!this.graph.has(start)) {
      await logMessage("Not a valid vertex to start.");
      return;
    }

    await highlightLine(6);
    setVariables((vars) => ({ ...vars, 
        start: { variable_name: 'start', value: start }
    }));
    setHighlightedVariables(['start']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(7);
    let visited = new Set();
    setSetsVariables((vars) => ({ ...vars, visited: { variable_name: 'visited', value: visited } }));
    setHighlightedSetVariables(['visited']);
    await customSleep();
    setHighlightedSetVariables([]);

    await highlightLine(8);
    let queue = [""];
    setHighlightedVariables(['start']);
    setStackVariables({ queue: { variable_name: 'queue', value: queue } });
    setHighlightedStackIndex([{ stackName: 'queue', index: queue.length - 1 }]);
    await customSleep(1200);
    queue[queue.length - 1] = start;
    setStackVariables({ queue: { variable_name: 'queue', value: queue } });
    await customSleep();
    setHighlightedStackIndex([]); 
    setHighlightedVariables([]);

    await highlightLine(9);
    setHighlightedVariables(['start']);
    setHighlightedGraphVariables([this.address[start]]);
    visited.add(start);
    setHighlightedSetIndex([{ setName: 'visited', key: start }]);
    await customSleep();
    setHighlightedVariables([]);
    setHighlightedGraphVariables([]);
    setHighlightedSetIndex([]);

    if (!(queue.length > 0)) {
      await highlightLine(10);
      setHighlightedStackVariables(['queue']);
      await customSleep();
      setHighlightedStackVariables([]);
    }
    while (queue.length > 0) {
      await highlightLine(10);
      setHighlightedStackVariables(['queue']);
      await customSleep();
      setHighlightedStackVariables([]);

      await highlightLine(11);
      setHighlightedStackIndex([{ stackName: 'queue', index: 0 }]);
      await customSleep(1200);
      setHighlightedStackIndex([]);
      let vertex = queue.shift();
      setVariables((vars) => ({ ...vars, 
        vertex: { variable_name: 'vertex', value: vertex }
      }));
      setStackVariables({ queue: { variable_name: 'queue', value: queue } });
      setHighlightedVariables(['vertex']);
      await customSleep();
      setHighlightedVariables([]);

      await highlightLine(12);
      setHighlightedVariables(['vertex']);
      setHighlightedGraphVariables([this.address[vertex]]);
      await logMessage(`${vertex} `);
      await customSleep();
      setHighlightedVariables([]);
      setHighlightedGraphVariables([]);

      if (this.graph.get(vertex).length === 0) {
        await highlightLine(13);
        await logMessage(`Vertex ${vertex} has no neighbors.`);
        await customSleep();
      }

      let countNeighbor = 0;
      for (let neighbor of this.graph.get(vertex)) {
        countNeighbor += 1;
        
        await highlightLine(13);
        setHighlightedMapIndex([{ mapName: 'graph', key:  `${vertex}`}]);
        setVariables((vars) => ({ ...vars, 
          neighbor: { variable_name: 'neighbor', value: neighbor }
        }));
        setHighlightedVariables(['neighbor']);
        await customSleep();
        setHighlightedVariables([]);

        await highlightLine(14);
        setHighlightedVariables(['neighbor']);
        setHighlightedSetIndex([{ setName: 'visited', key: neighbor }]);
        await customSleep();
        setHighlightedVariables([]);
        setHighlightedSetIndex([]);
        if (!visited.has(neighbor)) {
          await highlightLine(15);
          setHighlightedVariables(['neighbor']);
          setHighlightedGraphVariables([this.address[neighbor]]);
          visited.add(neighbor);
          setHighlightedSetIndex([{ setName: 'visited', key: neighbor }]);
          await customSleep();
          setHighlightedVariables([]);
          setHighlightedGraphVariables([]);
          setHighlightedSetIndex([]);
          
          await highlightLine(16);
          queue.push("");
          setHighlightedVariables(['neighbor']);
          setStackVariables({ queue: { variable_name: 'queue', value: queue } });
          setHighlightedStackIndex([{ stackName: 'queue', index: queue.length - 1 }]);
          await customSleep(1200);
          queue[queue.length - 1] = neighbor;
          setStackVariables({ queue: { variable_name: 'queue', value: queue } });
          await customSleep();
          setHighlightedVariables([]);
          setHighlightedStackIndex([]);
        } else {
          await logMessage(`Neighbor ${neighbor} already visited. Iterating the next neighbor.`);
        }

        if (this.graph.get(vertex).length === countNeighbor) {
          await highlightLine(13);
          setVariables((vars) => {
            const { neighbor, ...rest } = vars;  // Destructure and exclude `neighbor`
            return { ...rest };  // Return the updated state without `neighbor`
          });
          await logMessage(`Loop through all the neighbors completed.`);
          await customSleep();
        }
      }
      setHighlightedMapIndex([]);

      if (!(queue.length > 0)) {
        await highlightLine(10);
        setHighlightedStackVariables(['queue']);
        await customSleep();
        setHighlightedStackVariables([]);
      }
    }
    // Emptying vaiables
    setVariables(() => ({}));

    // Emptying stack variable
    setStackVariables(() => ({}));

    // Emptying set variable
    setSetsVariables(() => ({}));
  }

}

export const graphPython = async (
  graphInput,
  setGraphVariables,
  setmapVariables
) => {
  const graph = new GraphForPython();
  
  setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: {" ": " "} } }));
  
  await graph.addEdge(
    graphInput[0][0],
    graphInput[0][1],
    false,
    setGraphVariables,
    setmapVariables
  );
  
  await graph.addEdge(
    graphInput[1][0],
    graphInput[1][1],
    false,
    setGraphVariables,
    setmapVariables
  );
  
  await graph.addEdge(
    graphInput[2][0],
    graphInput[2][1],
    false,
    setGraphVariables,
    setmapVariables
  );
  
  await graph.addEdge(
    graphInput[3][0],
    graphInput[3][1],
    false,
    setGraphVariables,
    setmapVariables
  );
  
  await graph.addEdge(
    graphInput[4][0],
    graphInput[4][1],
    true,
    setGraphVariables,
    setmapVariables
  );

  return graph;
}

export const graphBFSPython = async (
  start,
  globalGraphInstance,
  highlightLine,
  setVariables,
  setHighlightedVariables,
  setHighlightedGraphVariables,
  setSetsVariables,
  setHighlightedMapIndex,
  setHighlightedSetIndex,
  setHighlightedSetVariables,
  setStackVariables,
  setHighlightedStackVariables,
  setHighlightedStackIndex,
  logMessage,
  customSleep
) => {

  await globalGraphInstance.bfs(
    start,
    highlightLine,
    setVariables,
    setHighlightedVariables,
    setHighlightedGraphVariables,
    setSetsVariables,
    setHighlightedMapIndex,
    setHighlightedSetIndex,
    setHighlightedSetVariables,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    logMessage,
    customSleep
  );
}


class GraphForJava {
  constructor() {
    this.graph = new Map();
    this.address = {};
  }

  async addEdge(
    vertex,
    neighbor,
    directed,
    setGraphVariables,
    setmapVariables
  ) {
    if (!this.graph.has(vertex)) {
      this.graph.set(vertex, []);

      this.address[vertex] = generateMemoryAddress();
      setGraphVariables((vars) => ({ ...vars, 
        [vertex]: { 
          variable_name: vertex,
          address: this.address[vertex],
          edges: []
        }
      }));
      let _graph = {};
      for (let [vertex, neighbors] of this.graph.entries()) {
        _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
      }
      setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
    }

    if (!this.graph.has(neighbor)) {
      this.graph.set(neighbor, []);

      this.address[neighbor] = generateMemoryAddress();
      setGraphVariables((vars) => ({ ...vars, 
        [neighbor]: { 
          variable_name: neighbor,
          address: this.address[neighbor],
          edges: []
        }
      }));
      let _graph = {};
      for (let [vertex, neighbors] of this.graph.entries()) {
        _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
      }
      setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
    }

    this.graph.get(vertex).push(neighbor);
    let _graph = {};
    for (let [vertex, neighbors] of this.graph.entries()) {
      _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
    }
    
    setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
    setGraphVariables((vars) => ({ ...vars, 
      [vertex]: { 
        variable_name: vertex,
        address: this.address[vertex],
        edges: this.graph.get(vertex)
      }
    }));
    
    if (!directed) {
      this.graph.get(neighbor).push(vertex);
      let _graph = {};
      for (let [vertex, neighbors] of this.graph.entries()) {
        _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
      }
      setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
      setGraphVariables((vars) => ({ ...vars, 
        [neighbor]: { 
          variable_name: neighbor,
          address: this.address[neighbor],
          edges: this.graph.get(neighbor)
        }
      }));
    }
  }

  async bfs(
    start,
    highlightLine,
    setVariables,
    setHighlightedVariables,
    setHighlightedGraphVariables,
    setSetsVariables,
    setHighlightedMapIndex,
    setHighlightedSetIndex,
    setHighlightedSetVariables,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    logMessage,
    customSleep
  ) {
    if (!this.graph.has(start)) {
      await logMessage("Not a valid vertex to start.");
      return;
    }

    await highlightLine(9);
    setVariables((vars) => ({ ...vars, 
        start: { variable_name: 'start', value: start }
    }));
    setHighlightedVariables(['start']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(10);
    let visited = new Set();
    setSetsVariables((vars) => ({ ...vars, visited: { variable_name: 'visited', value: visited } }));
    setHighlightedSetVariables(['visited']);
    await customSleep();
    setHighlightedSetVariables([]);

    await highlightLine(11);
    let queue = [];
    setStackVariables({ queue: { variable_name: 'queue', value: queue } });
    setHighlightedStackVariables(['queue']);
    await customSleep();
    setHighlightedStackVariables([]);
    
    await highlightLine(12);
    queue = [""];
    setHighlightedVariables(['start']);
    setStackVariables({ queue: { variable_name: 'queue', value: queue } });
    setHighlightedStackIndex([{ stackName: 'queue', index: queue.length - 1 }]);
    await customSleep(1200);
    queue[queue.length - 1] = start;
    setStackVariables({ queue: { variable_name: 'queue', value: queue } });
    await customSleep();
    setHighlightedStackIndex([]); 
    setHighlightedVariables([]);

    await highlightLine(13);
    setHighlightedVariables(['start']);
    setHighlightedGraphVariables([this.address[start]]);
    visited.add(start);
    setHighlightedSetIndex([{ setName: 'visited', key: start }]);
    await customSleep();
    setHighlightedVariables([]);
    setHighlightedGraphVariables([]);
    setHighlightedSetIndex([]);

    if (!(queue.length > 0)) {
      await highlightLine(14);
      setHighlightedStackVariables(['queue']);
      await customSleep();
      setHighlightedStackVariables([]);
    }
    while (queue.length > 0) {
      await highlightLine(14);
      setHighlightedStackVariables(['queue']);
      await customSleep();
      setHighlightedStackVariables([]);

      await highlightLine(15);
      setHighlightedStackIndex([{ stackName: 'queue', index: 0 }]);
      await customSleep(1200);
      setHighlightedStackIndex([]);
      let vertex = queue.shift();
      setVariables((vars) => ({ ...vars, 
        vertex: { variable_name: 'vertex', value: vertex }
      }));
      setStackVariables({ queue: { variable_name: 'queue', value: queue } });
      setHighlightedVariables(['vertex']);
      await customSleep();
      setHighlightedVariables([]);

      await highlightLine(16);
      setHighlightedVariables(['vertex']);
      setHighlightedGraphVariables([this.address[vertex]]);
      await logMessage(`${vertex} `);
      await customSleep();
      setHighlightedVariables([]);
      setHighlightedGraphVariables([]);

      if (this.graph.get(vertex).length === 0) {
        await highlightLine(17);
        await logMessage(`Vertex ${vertex} has no neighbors.`);
        await customSleep();
      }

      let countNeighbor = 0;
      for (let neighbor of this.graph.get(vertex)) {
        countNeighbor += 1;
        
        await highlightLine(17);
        setHighlightedMapIndex([{ mapName: 'graph', key:  `${vertex}`}]);
        setVariables((vars) => ({ ...vars, 
          neighbor: { variable_name: 'neighbor', value: neighbor }
        }));
        setHighlightedVariables(['neighbor']);
        await customSleep();
        setHighlightedVariables([]);

        await highlightLine(18);
        setHighlightedVariables(['neighbor']);
        setHighlightedSetIndex([{ setName: 'visited', key: neighbor }]);
        await customSleep();
        setHighlightedVariables([]);
        setHighlightedSetIndex([]);
        if (!visited.has(neighbor)) {
          await highlightLine(19);
          setHighlightedVariables(['neighbor']);
          setHighlightedGraphVariables([this.address[neighbor]]);
          visited.add(neighbor);
          setHighlightedSetIndex([{ setName: 'visited', key: neighbor }]);
          await customSleep();
          setHighlightedVariables([]);
          setHighlightedGraphVariables([]);
          setHighlightedSetIndex([]);
          
          await highlightLine(20);
          queue.push("");
          setHighlightedVariables(['neighbor']);
          setStackVariables({ queue: { variable_name: 'queue', value: queue } });
          setHighlightedStackIndex([{ stackName: 'queue', index: queue.length - 1 }]);
          await customSleep(1200);
          queue[queue.length - 1] = neighbor;
          setStackVariables({ queue: { variable_name: 'queue', value: queue } });
          await customSleep();
          setHighlightedVariables([]);
          setHighlightedStackIndex([]);
        } else {
          await logMessage(`Neighbor ${neighbor} already visited. Iterating the next neighbor.`);
        }

        if (this.graph.get(vertex).length === countNeighbor) {
          await highlightLine(17);
          setVariables((vars) => {
            const { neighbor, ...rest } = vars;  // Destructure and exclude `neighbor`
            return { ...rest };  // Return the updated state without `neighbor`
          });
          await logMessage(`Loop through all the neighbors completed.`);
          await customSleep();
        }
      }
      setHighlightedMapIndex([]);

      if (!(queue.length > 0)) {
        await highlightLine(14);
        setHighlightedStackVariables(['queue']);
        await customSleep();
        setHighlightedStackVariables([]);
      }
    }
    // Emptying vaiables
    setVariables(() => ({}));

    // Emptying stack variable
    setStackVariables(() => ({}));

    // Emptying set variable
    setSetsVariables(() => ({}));
  }

}

export const graphJava = async (
  graphInput,
  setGraphVariables,
  setmapVariables
  ) => {
    const graph = new GraphForJava();
    
    setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: {" ": " "} } }));
    
    await graph.addEdge(
      graphInput[0][0],
      graphInput[0][1],
      false,
      setGraphVariables,
      setmapVariables
    );
    
    await graph.addEdge(
      graphInput[1][0],
      graphInput[1][1],
      false,
      setGraphVariables,
      setmapVariables
    );
    
    await graph.addEdge(
      graphInput[2][0],
      graphInput[2][1],
      false,
      setGraphVariables,
      setmapVariables
    );
    
    await graph.addEdge(
      graphInput[3][0],
      graphInput[3][1],
      false,
      setGraphVariables,
      setmapVariables
    );
    
    await graph.addEdge(
      graphInput[4][0],
      graphInput[4][1],
      true,
      setGraphVariables,
      setmapVariables
    );  // Directed edge

    return graph;
}

export const graphBFSJava = async (
  start,
  globalGraphInstance,
  highlightLine,
  setVariables,
  setHighlightedVariables,
  setHighlightedGraphVariables,
  setSetsVariables,
  setHighlightedMapIndex,
  setHighlightedSetIndex,
  setHighlightedSetVariables,
  setStackVariables,
  setHighlightedStackVariables,
  setHighlightedStackIndex,
  logMessage,
  customSleep
) => {

  await globalGraphInstance.bfs(
    start,
    highlightLine,
    setVariables,
    setHighlightedVariables,
    setHighlightedGraphVariables,
    setSetsVariables,
    setHighlightedMapIndex,
    setHighlightedSetIndex,
    setHighlightedSetVariables,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    logMessage,
    customSleep
  );
}

class GraphForCpp {
  constructor() {
    this.graph = new Map();
    this.address = {};
  }

  async addEdge(
    vertex,
    neighbor,
    directed,
    setGraphVariables,
    setmapVariables
  ) {
    
    if (!this.graph.has(vertex)) {
      this.graph.set(vertex, []);

      this.address[vertex] = generateMemoryAddress();
      setGraphVariables((vars) => ({ ...vars, 
        [vertex]: { 
          variable_name: vertex,
          address: this.address[vertex],
          edges: []
        }
      }));
      let _graph = {};
      for (let [vertex, neighbors] of this.graph.entries()) {
        _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
      }
      setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
    }

    if (!this.graph.has(neighbor)) {
      this.graph.set(neighbor, []);

      this.address[neighbor] = generateMemoryAddress();
      setGraphVariables((vars) => ({ ...vars, 
        [neighbor]: { 
          variable_name: neighbor,
          address: this.address[neighbor],
          edges: []
        }
      }));

      let _graph = {};
      for (let [vertex, neighbors] of this.graph.entries()) {
        _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
      }
      setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
    }

    this.graph.get(vertex).push(neighbor);
    let _graph = {};
    for (let [vertex, neighbors] of this.graph.entries()) {
      _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
    }
    
    setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
    setGraphVariables((vars) => ({ ...vars, 
      [vertex]: { 
        variable_name: vertex,
        address: this.address[vertex],
        edges: this.graph.get(vertex)
      }
    }));
    
    if (!directed) {
      this.graph.get(neighbor).push(vertex);
      let _graph = {};
      for (let [vertex, neighbors] of this.graph.entries()) {
        _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
      }

      setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));

      setGraphVariables((vars) => ({ ...vars, 
        [neighbor]: { 
          variable_name: neighbor,
          address: this.address[neighbor],
          edges: this.graph.get(neighbor)
        }
      }));
    }
  }

  async bfs(
    start,
    highlightLine,
    setVariables,
    setHighlightedVariables,
    setHighlightedGraphVariables,
    setSetsVariables,
    setHighlightedMapIndex,
    setHighlightedSetIndex,
    setHighlightedSetVariables,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    logMessage,
    customSleep
  ) {
    if (!this.graph.has(start)) {
      await logMessage("Not a valid vertex to start.");
      return;
    }

    await highlightLine(14);
    setVariables((vars) => ({ ...vars, 
        start: { variable_name: 'start', value: start }
    }));
    setHighlightedVariables(['start']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(15);
    let visited = new Set();
    setSetsVariables((vars) => ({ ...vars, visited: { variable_name: 'visited', value: visited } }));
    setHighlightedSetVariables(['visited']);
    await customSleep();
    setHighlightedSetVariables([]);

    await highlightLine(16);
    let q = [];
    setStackVariables({ q: { variable_name: 'q', value: q } });
    setHighlightedStackVariables(['q']);
    await customSleep();
    setHighlightedStackVariables([]);
    
    await highlightLine(17);
    q = [""];
    setHighlightedVariables(['start']);
    setStackVariables({ q: { variable_name: 'q', value: q } });
    setHighlightedStackIndex([{ stackName: 'q', index: q.length - 1 }]);
    await customSleep(1200);
    q[q.length - 1] = start;
    setStackVariables({ q: { variable_name: 'q', value: q } });
    await customSleep();
    setHighlightedStackIndex([]); 
    setHighlightedVariables([]);

    await highlightLine(18);
    setHighlightedVariables(['start']);
    setHighlightedGraphVariables([this.address[start]]);
    visited.add(start);
    setHighlightedSetIndex([{ setName: 'visited', key: start }]);
    await customSleep();
    setHighlightedVariables([]);
    setHighlightedGraphVariables([]);
    setHighlightedSetIndex([]);

    if (!(q.length > 0)) {
      await highlightLine(19);
      setHighlightedStackVariables(['q']);
      await customSleep();
      setHighlightedStackVariables([]);
    }
    while (q.length > 0) {
      await highlightLine(19);
      setHighlightedStackVariables(['q']);
      await customSleep();
      setHighlightedStackVariables([]);

      await highlightLine(20);
      setHighlightedStackIndex([{ stackName: 'q', index: 0 }]);
      await customSleep(1200);
      setHighlightedStackIndex([]);
      let vertex = q[0];
      setVariables((vars) => ({ ...vars, 
        vertex: { variable_name: 'vertex', value: vertex }
      }));
      setHighlightedVariables(['vertex']);
      await customSleep();
      setHighlightedVariables([]);

      await highlightLine(21);
      setHighlightedStackIndex([{ stackName: 'q', index: 0 }]);
      await customSleep(1200);
      setHighlightedStackIndex([]);
      q.shift();
      setStackVariables({ q: { variable_name: 'q', value: q } });
      setHighlightedVariables(['vertex']);
      await customSleep();
      setHighlightedVariables([]);

      await highlightLine(22);
      setHighlightedVariables(['vertex']);
      setHighlightedGraphVariables([this.address[vertex]]);
      await logMessage(`${vertex} `);
      await customSleep();
      setHighlightedVariables([]);
      setHighlightedGraphVariables([]);

      if (this.graph.get(vertex).length === 0) {
        await highlightLine(23);
        await logMessage(`Vertex ${vertex} has no neighbors.`);
        await customSleep();
      }

      let countNeighbor = 0;
      for (let neighbor of this.graph.get(vertex)) {
        countNeighbor += 1;
        
        await highlightLine(23);
        setHighlightedMapIndex([{ mapName: 'graph', key:  `${vertex}`}]);
        setVariables((vars) => ({ ...vars, 
          neighbor: { variable_name: 'neighbor', value: neighbor }
        }));
        setHighlightedVariables(['neighbor']);
        await customSleep();
        setHighlightedVariables([]);

        await highlightLine(24);
        setHighlightedVariables(['neighbor']);
        setHighlightedSetIndex([{ setName: 'visited', key: neighbor }]);
        await customSleep();
        setHighlightedVariables([]);
        setHighlightedSetIndex([]);
        if (!visited.has(neighbor)) {
          await highlightLine(25);
          setHighlightedVariables(['neighbor']);
          setHighlightedGraphVariables([this.address[neighbor]]);
          visited.add(neighbor);
          setHighlightedSetIndex([{ setName: 'visited', key: neighbor }]);
          await customSleep();
          setHighlightedVariables([]);
          setHighlightedGraphVariables([]);
          setHighlightedSetIndex([]);
          
          await highlightLine(26);
          q.push("");
          setHighlightedVariables(['neighbor']);
          setStackVariables({ q: { variable_name: 'q', value: q } });
          setHighlightedStackIndex([{ stackName: 'q', index: q.length - 1 }]);
          await customSleep(1200);
          q[q.length - 1] = neighbor;
          setStackVariables({ q: { variable_name: 'q', value: q } });
          await customSleep();
          setHighlightedVariables([]);
          setHighlightedStackIndex([]);
        } else {
          await logMessage(`Neighbor ${neighbor} already visited. Iterating the next neighbor.`);
        }

        if (this.graph.get(vertex).length === countNeighbor) {
          await highlightLine(23);
          setVariables((vars) => {
            const { neighbor, ...rest } = vars;  // Destructure and exclude `neighbor`
            return { ...rest };  // Return the updated state without `neighbor`
          });
          await logMessage(`Loop through all the neighbors completed.`);
          await customSleep();
        }
      }
      setHighlightedMapIndex([]);

      if (!(q.length > 0)) {
        await highlightLine(19);
        setHighlightedStackVariables(['q']);
        await customSleep();
        setHighlightedStackVariables([]);
      }
    }
    // Emptying vaiables
    setVariables(() => ({}));

    // Emptying stack variable
    setStackVariables(() => ({}));

    // Emptying set variable
    setSetsVariables(() => ({}));
  }

}

export const graphCpp = async (
  graphInput,
  setGraphVariables,
  setmapVariables
  ) => {
    const graph = new GraphForCpp();
    setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: {" ": " "} } }));
    
    await graph.addEdge(
      graphInput[0][0],
      graphInput[0][1],
      false,
      setGraphVariables,
      setmapVariables
    );
    
    await graph.addEdge(
      graphInput[1][0],
      graphInput[1][1],
      false,
      setGraphVariables,
      setmapVariables
    );
    
    await graph.addEdge(
      graphInput[2][0],
      graphInput[2][1],
      false,
      setGraphVariables,
      setmapVariables
    );
    
    await graph.addEdge(
      graphInput[3][0],
      graphInput[3][1],
      false,
      setGraphVariables,
      setmapVariables
    );

    await graph.addEdge(
      graphInput[4][0],
      graphInput[4][1],
      true,
      setGraphVariables,
      setmapVariables
    );  // Directed edge

  return graph;
}

export const graphBFSCpp = async (
  start,
  globalGraphInstance,
  highlightLine,
  setVariables,
  setHighlightedVariables,
  setHighlightedGraphVariables,
  setSetsVariables,
  setHighlightedMapIndex,
  setHighlightedSetIndex,
  setHighlightedSetVariables,
  setStackVariables,
  setHighlightedStackVariables,
  setHighlightedStackIndex,
  logMessage,
  customSleep
) => {

  await globalGraphInstance.bfs(
    start,
    highlightLine,
    setVariables,
    setHighlightedVariables,
    setHighlightedGraphVariables,
    setSetsVariables,
    setHighlightedMapIndex,
    setHighlightedSetIndex,
    setHighlightedSetVariables,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    logMessage,
    customSleep
  );
}

class GraphForC {
  constructor(maxVertices) {
    this.graph = new Map();
    this._graph = {};
    this.address = {};
    this.adjLists = new Array(maxVertices).fill(null);
    this.maxVertices = maxVertices;
  }

  async createNode(
    v,
    setNodeVariables
  ) {

    let nodeAddress = generateMemoryAddress();
    let newNode = new Node(v, nodeAddress);
    setNodeVariables({
      [newNode.address]: { 
        variable_name: newNode.address,
        value: { data: '', next: '' },
        address: newNode.address,
      }
    });
    
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: ''},
        address: newNode.address,
      }
    }));
    
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: 'NULL'},
        address: newNode.address,
      }
    }));
    
    return newNode;
  }

  async createGraph(
    setVariables,
    setAdjList,
    setMapVariablesForC
  ) {

    ;
    setMapVariablesForC((vars) => ({ ...vars, graph: { variable_name: 'graph', value: {" ": " "} } }));
    // let _graph = {};
    for (let i = 0; i < this.maxVertices; i++) {
      this._graph[i] = ``;
      setAdjList((prevAdjList) => {
        const updatedList = [...prevAdjList];
  
        if (updatedList[i]) {
          updatedList[i] = [...updatedList[i], ``];
        } else {
          updatedList[i] = ``;
        }
  
        return updatedList;
      });
    }
    setMapVariablesForC((vars) => ({ ...vars, graph: { variable_name: 'graph', value: this._graph } }));
    

    for (let i = 0; i < this.maxVertices; i++) {
      setVariables((vars) => ({ ...vars, 
        i: { variable_name: 'i', value: i }
      }));
      
      this._graph[i] = 'NULL';
      setMapVariablesForC((vars) => ({ ...vars, graph: { variable_name: 'graph', value: this._graph } }));
      setAdjList((prevAdjList) => {
        const updatedList = [...prevAdjList];
  
        if (updatedList[i]) {
          updatedList[i] = [...updatedList[i], 'NULL'];
        } else {
          updatedList[i] = 'NULL';
        }
  
        return updatedList;
      });
    }

  }

  async addEdge(
    vertex,
    neighbor,
    directed,
    setGraphVariables,
    setVariables,
    setAdjList,
    setNodeVariables
  ) {

    let newNode = await this.createNode(
      neighbor,
      setNodeVariables
    )
    
    newNode.next = this.adjLists[vertex];
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: `${this._graph[vertex]}`},
        address: newNode.address,
      }
    }));
    
    setNodeVariables(() => ({}));
    this.adjLists[vertex] = newNode;
    this._graph[vertex] = newNode.address;
    setAdjList((prevAdjList) => {
      const updatedList = [...prevAdjList];
      let node = {
        'address': newNode.address,
        'data': newNode.data,
        'next': newNode.next === null ? 'NULL' : newNode.next.address,
      };

      if (updatedList[vertex] && typeof updatedList[vertex] !== 'string') {
        updatedList[vertex] = [node, ...updatedList[vertex]];
      } else {
        updatedList[vertex] = [node];
      }

      return updatedList;
    });
    
    if (!this.graph.has(vertex)) {
      this.graph.set(vertex, []);
      this.address[vertex] = generateMemoryAddress();

      setGraphVariables((vars) => ({ ...vars, 
        [vertex]: { 
          variable_name: vertex,
          address: this.address[vertex],
          edges: []
        }
      }));
    }

    if (!this.graph.has(neighbor)) {
      this.graph.set(neighbor, []);
      this.address[neighbor] = generateMemoryAddress();

      setGraphVariables((vars) => ({ ...vars, 
        [neighbor]: { 
          variable_name: neighbor,
          address: this.address[neighbor],
          edges: []
        }
      }));
    }

    this.graph.get(vertex).push(neighbor);
    setGraphVariables((vars) => ({ ...vars, 
      [vertex]: { 
        variable_name: vertex,
        address: this.address[vertex],
        edges: this.graph.get(vertex)
      }
    }));

    
    if (!directed) {
      let newNode = await this.createNode(
        vertex,
        setNodeVariables
      )
      
      newNode.next = this.adjLists[neighbor];
      setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
          variable_name: newNode.address,
          value: {data: newNode.data, next: `${this._graph[neighbor]}`},
          address: newNode.address
        }
      }));
      
      setNodeVariables(() => ({}));
      this.adjLists[neighbor] = newNode;
      this._graph[neighbor] = newNode.address;
      setAdjList((prevAdjList) => {
        const updatedList = [...prevAdjList];
        let node = {
          'address': newNode.address,
          'data': newNode.data,
          'next': newNode.next === null ? 'NULL' : newNode.next.address
        };
  
        if (updatedList[neighbor] && typeof updatedList[neighbor] !== 'string') {
          updatedList[neighbor] = [node, ...updatedList[neighbor]];
        } else {
          updatedList[neighbor] = [node];
        }
  
        return updatedList;
      });

      this.graph.get(neighbor).push(vertex);
      setGraphVariables((vars) => ({ ...vars, 
        [neighbor]: { 
          variable_name: neighbor,
          address: this.address[neighbor],
          edges: this.graph.get(neighbor)
        }
      }));
    }

    // Emptying vaiables
    setVariables(() => ({}));
  }

  async bfs(
    start,
    highlightLine,
    setVariables,
    setHighlightedVariables,
    setHighlightedGraphVariables,
    setHighlightedMapVariablesForC,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    setStrikeThroughStackIndex,
    setHighlightedMapIndexForC,
    setHighlightedNodeVariables,
    setNodeVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    logMessage,
    customSleep
  ) {
    // if (!this.graph.has(start)) {
    //   await logMessage("Not a valid vertex to start.");
    //   return;
    // }

    await highlightLine(5);
    setVariables((vars) => ({ ...vars, 
        start: { variable_name: 'start', value: start }
    }));
    setHighlightedVariables(['start']);
    setHighlightedMapVariablesForC(['graph']);
    await customSleep();
    setHighlightedVariables([]);
    setHighlightedMapVariablesForC([]);

    await highlightLine(6);
    const visited = new Array(this.maxVertices).fill(false);
    const _visited = new Array(this.maxVertices).fill(false);
    setStackVariables({ visited: { variable_name: 'visited', value: _visited } });
    setHighlightedStackVariables(['visited']);
    setHighlightedVariables(['MAX_VERTICES']);
    await customSleep(1200);
    setHighlightedStackVariables([]);
    setHighlightedStackIndex([{ stackName: 'visited', index: 0 }]);
    await customSleep(1200);
    _visited[0] = 0;
    setStackVariables({ visited: { variable_name: 'visited', value: _visited } });
    await customSleep();
    setHighlightedStackIndex([]);
    setHighlightedVariables([]);
    

    await highlightLine(7);
    const queue = [];
    const _queue = new Array(this.maxVertices).fill(false);
    setStackVariables((vars) => ({ ...vars, queue: { variable_name: 'queue', value: _queue } }));
    setHighlightedStackVariables(['queue']);
    setHighlightedVariables(['MAX_VERTICES']);
    await customSleep();
    setHighlightedStackVariables([]);
    setHighlightedVariables([]);

    await highlightLine(8);
    let front = 0;
    let rear = 0;
    setVariables((vars) => ({ ...vars, 
        front: { variable_name: 'front', value: front },
        rear: { variable_name: 'rear', value: rear }
    }));
    setHighlightedVariables(['front', 'rear']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(10);
    setHighlightedVariables(['start']);
    setHighlightedGraphVariables([this.address[start]]);
    visited[start] = true;
    setHighlightedStackIndex([{ stackName: 'visited', index: start }]);
    await customSleep(1200);
    _visited[start] = 1;
    setStackVariables((vars) => ({ ...vars, visited: { variable_name: 'visited', value: _visited } }));
    await customSleep();
    setHighlightedVariables([]);
    setHighlightedGraphVariables([]);
    setHighlightedStackIndex([]);

    await highlightLine(11);
    setHighlightedVariables(['start', 'rear']);
    setHighlightedStackIndex([{ stackName: 'queue', index: rear }]);
    setStrikeThroughStackIndex([]);
    await customSleep(1200);
    queue.push(start);
    _queue[rear] = start;
    setStackVariables((vars) => ({ ...vars, queue: { variable_name: 'queue', value: _queue } }));
    await customSleep(1200);
    rear += 1;
    setVariables((vars) => ({ ...vars, 
      rear: { variable_name: 'rear', value: rear }
    }));
    await customSleep();
    setHighlightedStackIndex([]); 
    setHighlightedVariables([]);

    if (!(front < rear)) {
      await highlightLine(13);
      setHighlightedVariables(['front', 'rear']);
      await customSleep();
      setHighlightedVariables([]);
    }
    while (front < rear) {
      await highlightLine(13);
      setHighlightedVariables(['front', 'rear']);
      await customSleep();
      setHighlightedVariables([]);

      await highlightLine(14);
      const vertex = queue[front];
      setHighlightedVariables(['front']);
      setHighlightedGraphVariables([this.address[vertex]]);
      setHighlightedStackIndex([{ stackName: 'queue', index: front }]);
      await customSleep(1200);
      setVariables((vars) => ({ ...vars, 
        vertex: { variable_name: 'vertex', value: vertex }
      }));
      setHighlightedVariables(['front', 'vertex']);
      await customSleep(1200);
      let strikeIndex = front;
      setStrikeThroughStackIndex((prevState) => [
          ...prevState, 
          { stackName: 'queue', index: strikeIndex }
      ]);
      front += 1;
      setVariables((vars) => ({ ...vars, 
        front: { variable_name: 'front', value: front }
      }));
      await customSleep();
      setHighlightedVariables([]);
      setHighlightedGraphVariables([]);
      setHighlightedStackIndex([]);

      await highlightLine(15);
      setHighlightedGraphVariables([this.address[vertex]]);
      setHighlightedVariables(['vertex']);
      await logMessage(`${vertex} `);
      await customSleep();
      setHighlightedGraphVariables([]);
      setHighlightedVariables([]);

      await highlightLine(17);
      setHighlightedVariables(['vertex']);
      setHighlightedGraphVariables([this.address[vertex]]);
      setHighlightedMapIndexForC([{ mapName: 'graph', key: `${vertex}` }]);
      let temp = this.adjLists[vertex];
      setHighlightedNodeVariables([`${temp ? temp.address : ''}`]);
      setHighlightNodeAddress([`${temp ? temp.address : ''}`]);
      await customSleep(1200);
      setHighlightedNodeVariables([]);
      let nodeAddress = generateMemoryAddress();
      setNodeVariables({ nodeAddress: { 
        variable_name: nodeAddress,
        node_name: 'temp',
        value: {
          data: temp ? temp.data : 'NULL',
          next: temp ? temp.next ? temp.next.address : 'NULL' : 'NULL'
        },
        address: nodeAddress,
      }});
      setHighlightedNodeVariables([`${nodeAddress}`]);
      await customSleep();
      setHighlightedVariables([]);
      setHighlightedGraphVariables([]);
      setHighlightedMapIndexForC([]);
      setHighlightedNodeVariables([]);
      setHighlightNodeAddress([]);
      
      if (!(temp)) {
        await highlightLine(18);
        setHighlightedNodeVariables([`${nodeAddress}`]);
        await customSleep();
        setHighlightedNodeVariables([]);
      }
      while (temp) {
        await highlightLine(18);
        setHighlightedNodeVariables([`${nodeAddress}`]);
        await customSleep();
        setHighlightedNodeVariables([]);

        await highlightLine(19);
        setHighlightDataPart([`${nodeAddress}`]);
        setHighlightedStackIndex([{ stackName: 'visited', index: temp.data }]);
        await customSleep();
        setHighlightedNodeVariables([]);
        setHighlightedStackIndex([]);
        setHighlightDataPart([]);
        if (!visited[temp.data]) {
          await highlightLine(20);
          setHighlightedStackIndex([{ stackName: 'visited', index: temp.data }]);
          await customSleep(1200);
          setHighlightDataPart([`${nodeAddress}`]);
          await customSleep(1200);
          visited[temp.data] = true;
          _visited[temp.data] = 1;
          setStackVariables((vars) => ({ ...vars, 
            visited: { variable_name: 'visited', value: _visited } 
          }));
          await customSleep();
          setHighlightedStackIndex([]);
          setHighlightDataPart([]);

          await highlightLine(21);
          setHighlightedStackIndex([{ stackName: 'queue', index: rear }]);
          setHighlightedVariables(['rear']);
          await customSleep(1200);
          setHighlightDataPart([`${nodeAddress}`]);
          setHighlightedGraphVariables([this.address[temp.data]]);
          await customSleep(1200);
          queue.push(temp.data);
          _queue[rear] = temp.data;
          setStackVariables((vars) => ({ ...vars, 
            queue: { variable_name: 'queue', value: _queue } 
          }));
          await customSleep(1200);
          rear += 1;
          setVariables((vars) => ({ ...vars, 
            rear: { variable_name: 'rear', value: rear }
          }));
          await customSleep();
          setHighlightedStackIndex([]);
          setHighlightedGraphVariables([]);
          setHighlightDataPart([]);
          setHighlightedVariables([]);
        }

        await highlightLine(23);
        setHighlightNextAddressPart([`${nodeAddress}`]);
        setHighlightedNodeVariables([`${temp.next ? temp.next.address : ''}`]);
        await customSleep(1200);
        temp = temp.next;
        nodeAddress = generateMemoryAddress();
        setNodeVariables({ nodeAddress: { 
          variable_name: nodeAddress,
          node_name: 'temp',
          value: {
            data: temp ? temp.data : 'NULL',
            next: temp ? temp.next ? temp.next.address : 'NULL' : 'NULL'
          },
          address: nodeAddress,
        }});
        setHighlightedNodeVariables([`${nodeAddress}`]);
        await customSleep();
        setHighlightedNodeVariables([]);

        if (!(temp)) {
          await highlightLine(18);
          setHighlightedNodeVariables([`${nodeAddress}`]);
          await customSleep();
          setHighlightedNodeVariables([]);
        }
      }

      if (!(front < rear)) {
        await highlightLine(13);
        setHighlightedVariables(['front', 'rear']);
        await customSleep();
        setHighlightedVariables([]);
      }

      // Emptying node vaiables
      setNodeVariables(() => ({}));
    }

    // Emptying vaiables
    setVariables(() => ({}));

    // Emptying stack variable
    setStackVariables(() => ({}));
  }

}

export const graphC = async (
  maxVertices,
  graphInput,
  setGraphVariables,
  setVariables,
  setAdjList,
  setMapVariablesForC,
  setNodeVariables,
  ) => {
    setVariables((vars) => ({ ...vars, 
      MAX_VERTICES: { variable_name: 'MAX_VERTICES', value: maxVertices }
    }));
    
    const graph = new GraphForC(maxVertices);
    await graph.createGraph(
      setVariables,
      setAdjList,
      setMapVariablesForC
    );
    
    await graph.addEdge(
      graphInput[0][0],
      graphInput[0][1],
      false,
      setGraphVariables,
      setVariables,
      setAdjList,
      setNodeVariables
    );
    
    await graph.addEdge(
      graphInput[1][0],
      graphInput[1][1],
      false,
      setGraphVariables,
      setVariables,
      setAdjList,
      setNodeVariables
    );
    
    await graph.addEdge(
      graphInput[2][0],
      graphInput[2][1],
      false,
      setGraphVariables,
      setVariables,
      setAdjList,
      setNodeVariables
    );
    
    await graph.addEdge(
      graphInput[3][0],
      graphInput[3][1],
      false,
      setGraphVariables,
      setVariables,
      setAdjList,
      setNodeVariables
    );

    await graph.addEdge(
      graphInput[4][0],
      graphInput[4][1],
      true,
      setGraphVariables,
      setVariables,
      setAdjList,
      setNodeVariables
    );
  
    return graph;
}

export const graphBFSC = async (
  start,
  globalGraphInstance,
  highlightLine,
  setVariables,
  setHighlightedVariables,
  setHighlightedGraphVariables,
  setHighlightedMapVariablesForC,
  setStackVariables,
  setHighlightedStackVariables,
  setHighlightedStackIndex,
  setStrikeThroughStackIndex,
  setHighlightedMapIndexForC,
  setHighlightedNodeVariables,
  setNodeVariables,
  setHighlightDataPart,
  setHighlightNextAddressPart,
  setHighlightNodeAddress,
  logMessage,
  customSleep
) => {

  await globalGraphInstance.bfs(
    start,
    highlightLine,
    setVariables,
    setHighlightedVariables,
    setHighlightedGraphVariables,
    setHighlightedMapVariablesForC,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    setStrikeThroughStackIndex,
    setHighlightedMapIndexForC,
    setHighlightedNodeVariables,
    setNodeVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    logMessage,
    customSleep
  );
}
