import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { countries } from './countries';

const UserProfile = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [showAvatarModal, setShowAvatarModal] = useState(false);
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState("profile.png");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const avatars = [
    "/avatars/avataaars_0.png",
    "/avatars/avataaars_1.png",
    "/avatars/avataaars_2.png",
    "/avatars/avataaars_3.png",
    "/avatars/avataaars_4.png",
    "/avatars/avataaars_5.png",
    "/avatars/avataaars_6.png",
    "/avatars/avataaars_7.png",
  ];

  useEffect(() => {
    // Set an SEO-friendly page title
    document.title = `Profile | Coding Canvas`;

    // Set meta description for SEO
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", `Explore interactive tutorials, code samples, and exercises to learn programming visually on Coding Canvas.`);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (!token) {
      // If user is not logged in, redirect to home
      navigate('/');
      return;
    }

    // Fetch user info if logged in
    const fetchUserInfo = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/api/user-info/`, {
          headers: { Authorization: `Token ${token}` },
        });
        const data = response.data;
        setUserInfo(data);
        setFirstName(data.first_name);
        setLastName(data.last_name);
        setCountry(data.country_code);
        setSelectedAvatar(data.avatar);
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem('isLoggedIn');
          localStorage.removeItem('userToken');

          // Dispatch custom event to update login status across the app
          window.dispatchEvent(new Event('loginStatusChange'));

          navigate('/');
        }
      } finally {
        setLoading(false);
      }
    };
    fetchUserInfo();
  }, [apiUrl, navigate]);

  const handleAvatarClick = () => setShowAvatarModal(true);
  const handleEditProfileClick = () => setShowEditProfileModal(true);

  const handleAvatarSelect = async (avatar) => {
    setSelectedAvatar(avatar.split('/').pop());
    const data = { avatar: avatar.split('/').pop() };
    await updateProfile(data);
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const updateProfile = async (data) => {
    const token = localStorage.getItem('userToken');
    const header = {
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json'
      }
    };

    setMessage('');
    setLoading(true);
    await delay(1000);

    try {
      const response = await axios.patch(`${apiUrl}/api/update-profile/`, data, header);
      if (response.status === 200) {
        setMessage('Profile updated successfully!');
        setUserInfo((prevInfo) => ({
          ...prevInfo,
          ...data,
          first_name: firstName,
          last_name: lastName,
          country_code: country,
          avatar: selectedAvatar,
        }));
      }
    } catch (error) {
      setMessage('Sorry! try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSaveProfile = async () => {
    const data = {
      country_code: country,
      first_name: firstName,
      last_name: lastName,
    };
    await updateProfile(data);
  };

  // Spinner for loading user info on initial load
  if (userInfo === null) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container my-5">
      {/* Profile Header */}
      <div className="card mb-4 shadow text-center">
        <div className="card-body">
          <div className="profile-avatar-wrapper" onClick={handleAvatarClick}>
            <div className="avatar-overlay"></div>
            <img
              src={`/avatars/${selectedAvatar}`}
              alt="User Profile"
              className="rounded-circle mb-3 profile-avatar"
            />
            <i className="fas fa-pencil-alt edit-icon"></i>
          </div>
          <h2 className="mb-0">{firstName} {lastName}</h2>
          <p className="text-muted">From: {userInfo?.country_code || "IND"}</p>
          <span className="badge bg-primary me-2">Premium Member</span>
          <button
            className="btn btn-outline-dark btn-sm custom-edit-btn"
            onClick={handleEditProfileClick}
          >
            <i className="fas fa-pencil-alt"></i> Edit Profile
          </button>
        </div>
      </div>

      {/* Avatar Selection Modal */}
      {showAvatarModal && (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modal-glass-avatar">
              <div className="modal-header">
                <h5 className="modal-title-avatar">Select Your Avatar</h5>
              </div>
              <div className="modal-body">
                <div className="d-flex flex-wrap justify-content-center">
                  {avatars.map((avatar, index) => (
                    <img
                      key={index}
                      src={avatar}
                      alt={`Avatar ${index + 1}`}
                      className="m-2 rounded-circle avatar-in-modal"
                      style={{ width: '80px', height: '80px', cursor: 'pointer' }}
                      onClick={() => handleAvatarSelect(avatar)}
                    />
                  ))}
                </div>
              </div>
              <div className="modal-footer">
                {/* Existing modal spinner behavior remains unchanged */}
                {loading && <div className="text-center my-2"><div className="spinner-border" role="status"></div></div>}
                {message && <p className="text-center mt-2">{message}</p>}
                <button type="button" className="btn btn-dark" onClick={() => {
                  setMessage('');
                  setShowAvatarModal(false);
                }}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Edit Profile Modal */}
      {showEditProfileModal && (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modal-glass-avatar">
              <div className="modal-header">
                <h5 className="modal-title-avatar">Edit Profile</h5>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="firstName" className="form-label">First Name</label>
                        <input
                          type="text"
                          className="form-control custom-input"
                          id="firstName"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="lastName" className="form-label">Last Name</label>
                        <input
                          type="text"
                          className="form-control custom-input"
                          id="lastName"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="registerCountry" className="form-label">Country</label>
                        <select
                          id="registerCountry"
                          className="form-control custom-input custom-dropdown-profile"
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        >
                          <option value="">Select your country</option>
                          {countries.map((country) => (
                            <option key={country.code} value={country.code}>
                              {country.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input
                          type="text"
                          className="form-control custom-input disabled-input-box"
                          id="email"
                          value={userInfo?.email}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                {/* Existing modal spinner behavior remains unchanged */}
                {loading && <div className="text-center my-2"><div className="spinner-border" role="status"></div></div>}
                {message && <p className="text-center mt-2">{message}</p>}
                <button type="button" className="btn btn-dark" onClick={() => {
                  setMessage('');
                  setShowEditProfileModal(false);
                }}>Close</button>
                <button type="button" className="btn btn-success" onClick={handleSaveProfile}>Save Changes</button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Profile Details Sections */}
      <div className="row">
        {/* Account Overview */}
        <div className="col-md-4 mb-4">
          <div className="card h-100 shadow">
            <div className="card-header bg-dark text-white text-center">Account Overview</div>
            <div className="card-body">
              <p><strong>Email:</strong> {userInfo?.email}</p>
              <p><strong>Member Since:</strong> {userInfo?.memberSince || "Unknown"}</p>
              <p><strong>Subscription Plan:</strong> {userInfo?.subscriptionPlan || "Free"}</p>
              <button className="btn btn-dark btn-sm">Upgrade Plan</button>
            </div>
          </div>
        </div>

        {/* Learning Progress */}
        <div className="col-md-4 mb-4">
          <div className="card h-100 shadow">
            <div className="card-header bg-dark text-white text-center">Learning Progress</div>
            <div className="card-body">
              <h5>Completed Topics</h5>
              {/* <p><span className="badge bg-success">25</span> / 40 Topics</p> */}
              <p><span className="badge bg-success">{userInfo?.completedTopics || 0}</span> / 40 Topics</p>
            </div>
          </div>
        </div>

        {/* Certificates */}
        <div className="col-md-4 mb-4">
          <div className="card h-100 shadow">
            <div className="card-header bg-dark text-white text-center">Certificates</div>
            <div className="card-body">
              <ul className="list-unstyled">
                {/* <li><i className="bi bi-award-fill text-success"></i> Data Structures</li>
                <li><i className="bi bi-award-fill text-primary"></i> Algorithm Basics</li>
                <li><i className="bi bi-award-fill text-warning"></i> Web Development</li> */}
                {userInfo?.certificates?.map((cert, index) => (
                  <li key={index}><i className="bi bi-award-fill text-success"></i> {cert}</li>
                )) || <li>No certificates earned yet.</li>}
              </ul>
              <button className="btn btn-outline-dark btn-sm">View All Certificates</button>
            </div>
          </div>
        </div>
      </div>

      {/* Additional Profile Sections */}
      <div className="row">
        {/* Favorite Topics */}
        <div className="col-md-6 mb-4">
          <div className="card h-100 shadow">
            <div className="card-header bg-dark text-white text-center">Favorite Topics</div>
            <div className="card-body">
              <ul className="list-unstyled">
                {/* <li>🔹 JavaScript Fundamentals</li>
                <li>🔹 React Basics</li>
                <li>🔹 Data Structures & Algorithms</li>
                <li>🔹 Python Programming</li> */}

                {userInfo?.favoriteTopics?.map((topic, index) => (
                  <li key={index}>🔹 {topic}</li>
                )) || <li>🔹 No favorite topics selected.</li>}
              </ul>
            </div>
          </div>
        </div>

        {/* Recent Activity */}
        <div className="col-md-6 mb-4">
          <div className="card h-100 shadow">
            <div className="card-header bg-dark text-white text-center">Recent Activity</div>
            <div className="card-body">
              <ul className="list-unstyled">
                {/* <li>✔️ Completed "Introduction to Arrays" - 1 hour ago</li>
                <li>✔️ Started "Advanced React Hooks" - 2 days ago</li>
                <li>✔️ Earned Certificate in Data Structures - 5 days ago</li> */}
                {userInfo?.recentActivity?.map((activity, index) => (
                  <li key={index}>✔️ {activity}</li>
                )) || <li>No recent activity.</li>}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
