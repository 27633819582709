import React from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from '../AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';

import { useArithmeticOperations } from './useArithmeticOperations';
import codeSamples from './codeSamples';

const ArithmeticOperations = ({ language, subTopicId }) => {
  const x = 12;
  const y = 5;

  const {
    highlightedLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    isMuted,
    setDelay,
    setIsPaused,
    setIsMuted,
    runArithmeticOperations
  } = useArithmeticOperations(language, x, y);

  const codeLines = codeSamples[language] || [];

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeLines}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={"Arithmetic Operations"}
            subTopicId={subTopicId}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runArithmeticOperations}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              variables={variables}
              arrayVariables={arrayVariables}
              highlightedVariables={highlightedVariables}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
        <AlgorithmDescription />
      </div>
    </div>
  );
};

export default ArithmeticOperations;
