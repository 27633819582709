import React from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvasForGraph';
import AlgorithmDescription from '../AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';

import { useGraph } from './useGraph';
import codeSamples from './codeSamples';

const Graph = ({ language, subTopicId }) => {
  const graphInput = [[10, 20], [10, 25], [20, 42], [25, 42], [25, 50]];
  const maxVertices = 6; // For C Languuage Only
  const graphInputForC = [[1, 2], [1, 3], [2, 4], [3, 4], [3, 5]];

  const {
    highlightedLine,
    highlightedMultipleLines,
    focusedEndLine,
    callingLine,
    secondCallingLine,
    thirdCallingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    graphVariables,
    highlightedGraphVariables,
    variables,
    adjList,
    highlightedVariables,
    mapVariables,
    highlightedMapVariables,
    highlightedMapIndex,
    mapVariablesForC,
    highlightedMapVariablesForC,
    highlightedMapIndexForC,
    highlightedLineArrow,
    nodeVariables,
    highlightNodeAddress,
    highlightedNodeVariables,
    highlightDataPart,
    highlightNextAddressPart,
    isMuted,
    setDelay,
    setIsPaused,
    setIsMuted,
    runGraph
  } = useGraph(language, graphInput, graphInputForC, maxVertices);

  const codeLines = codeSamples[language] || [];

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeLines}
            highlightedLine={highlightedLine}
            highlightedMultipleLines={highlightedMultipleLines}
            focusedEndLine={focusedEndLine}
            topic={"Graph"}
            callingLine={callingLine}
            secondCallingLine={secondCallingLine}
            thirdCallingLine={thirdCallingLine}
            subTopicId={subTopicId}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runGraph}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              graphVariables={graphVariables}
              highlightedGraphVariables={highlightedGraphVariables}
              variables={variables}
              highlightedVariables={highlightedVariables}
              adjList={adjList}
              mapVariables={mapVariables}
              highlightedMapVariables={highlightedMapVariables}
              highlightedMapIndex={highlightedMapIndex}
              mapVariablesForC={mapVariablesForC}
              highlightedMapVariablesForC={highlightedMapVariablesForC}
              highlightedMapIndexForC={highlightedMapIndexForC}
              highlightedLineArrow={highlightedLineArrow}
              nodeVariables={nodeVariables}
              highlightNodeAddress={highlightNodeAddress}
              highlightedNodeVariables={highlightedNodeVariables}
              highlightDataPart={highlightDataPart}
              highlightNextAddressPart={highlightNextAddressPart}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
          <AlgorithmDescription />
      </div>
    </div>
  );
};

export default Graph;
