import React from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from '../AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';

import { useSequenceTypes } from './useSequenceTypes';
import codeSamples from './codeSamples';

const SequenceTypes = ({ language, subTopicId }) => {
  const name = "Alice";
  const fruits = ["apple", "banana", "cherry"];
  const coordinates = [10, 20, 30];
  const numbers = Array.from({ length: 5 }, (_, i) => i + 1);

  const {
    highlightedLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    highlightedArrayVariables,
    highlightedIndices,
    isMuted,
    setDelay,
    setIsPaused,
    setIsMuted,
    runSequenceTypes
  } = useSequenceTypes(name, fruits, coordinates, numbers);

  const codeLines = codeSamples[language] || [];

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeLines}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={"Sequence Types in Python"}
            subTopicId={subTopicId}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runSequenceTypes}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              variables={variables}
              arrayVariables={arrayVariables}
              highlightedVariables={highlightedVariables}
              highlightedArrayVariables={highlightedArrayVariables}
              highlightedIndices={highlightedIndices}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
        <AlgorithmDescription />
      </div>
    </div>
  );
};

export default SequenceTypes;
