import React from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from '../AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';

import { useTypeDef } from './useTypeDef';
import codeSamples from './codeSamples';

const TypeDef = ({ language, subTopicId }) => {
  const cakesBakedToday = 25;
  const cakesBakedYesterday = 30;

  const {
    highlightedLine,
    callingLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    highlightedVariables,
    isMuted,
    setDelay,
    setIsPaused,
    setIsMuted,
    runTypeDef
  } = useTypeDef(language, cakesBakedToday, cakesBakedYesterday);

  const codeLines = codeSamples[language] || [];

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeLines}
            callingLine={callingLine}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={`Typedef Example`}
            subTopicId={subTopicId}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runTypeDef}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              variables={variables}
              highlightedVariables={highlightedVariables}
              stringVariables={null}
              highlightedStringVariables={[]}
              highlightedStringIndices={[]}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
          <AlgorithmDescription />
      </div>
    </div>
  );
};

export default TypeDef;
