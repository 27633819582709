import { useState, useEffect, useRef } from 'react';
import { 
  personSetDetailsForCpp,
  personDisplayInfoForCpp
} from './structureFunctions';
import explanations from './explanations';

export const useStructureType = (language, initialStructure) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [delay, setDelay] = useState(2000);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [structureVariables, setStructureVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [stringVariables, setStringVariables] = useState({});
  const [highlightedStringVariables, setHighlightedStringVariables] = useState([]);
  const [highlightedStructureVariables, setHighlightedStructureVariables] = useState([]);
  const [highlightedStructureMethods, setHighlightedStructureMethods] = useState([]);
  const [isMuted, setIsMuted] = useState(true);
  const isPausedRef = useRef(isPaused);
  const delayRef = useRef(delay);
  const isMutedRef = useRef(isMuted);
  const synth = window.speechSynthesis;

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setCallingLine(null);
    setFocusedEndLine(null);
    setVariables({});
    setStructureVariables({});
    setLogs([]);
    setHighlightedVariables([]);
    setHighlightedStructureVariables([]);
    setHighlightedStructureMethods([]);
    setStringVariables({});
    setHighlightedStringVariables([]);
  };

  const speak = (message) => {
    return new Promise((resolve) => {
      if (isMutedRef.current) {
        resolve();
        return;
      }
      const utterance = new SpeechSynthesisUtterance(message);
      utterance.onend = resolve;
      synth.speak(utterance);
    });
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });
    setHighlightedLine(lineNumber);    
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const highlightCallingLine = async (lineNumber) => {
    setCallingLine(lineNumber);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async (time=null) => {
    let start = Date.now();
    let ms;
    if (time === null) {
        ms = delayRef.current;
    } else {
        ms = time;
    }
    
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runStructureTypeC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(8);
    let structureAttributes = [
      {
        name: 'name',
        dataType: 'string'
      },
      {
        name: 'age',
        dataType: 'int'
      }
    ];

    setStructureVariables((vars) => ({ ...vars, student1: { 
      objectName: 'student1', 
      structureName: 'Student',
      value: {attributes: structureAttributes, methods: []} 
    } }));
    setHighlightedStructureVariables(['student1']);
    await customSleep();
    setHighlightedStructureVariables([]);

    await highlightLine(10);
    setStringVariables((vars) => ({ ...vars, name: { variable_name: 'name', value: initialStructure.name } }));
    setHighlightedStringVariables(['name']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(11);
    setVariables((vars) => ({ ...vars, age: { variable_name: 'age', value: initialStructure.age } }));
    setHighlightedVariables(['age']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(13);
    setHighlightedStringVariables(['name']);
    await logMessage(`Student Name: ${initialStructure.name}`);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(14);
    setHighlightedVariables(['age']);
    await logMessage(`Student Age: ${initialStructure.age}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(16);
    await customSleep()

    await focusEndLine(17);
    setIsRunning(false);
  };

  const runStructureTypeCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(19);
    let structureAttributes = [
      {
        name: 'name',
        dataType: 'string'
      },
      {
        name: 'age',
        dataType: 'int'
      }
    ];

    let structureMethods = [
      {
        name: 'setDetails',
        type: 'member',
        parameters: [
          {name: 'personName', dataType: 'string'},
          {name: 'personAge', dataType: 'int'}
        ],
        returnType: 'void'
      },
      {
        name: 'displayInfo',
        type: 'member',
        parameters: [],
        returnType: 'void'
      }
    ];

    setStructureVariables((vars) => ({ ...vars, person1: { 
      objectName: 'person1', 
      structureName: 'Person',
      value: {attributes: structureAttributes, methods: structureMethods} 
    } }));
    setHighlightedStructureVariables(['person1']);
    await customSleep();
    setHighlightedStructureVariables([]);

    await highlightLine(20);
    await customSleep();

    await highlightCallingLine(20);
    setHighlightedStructureMethods(['setDetails']);
    await personSetDetailsForCpp(
      initialStructure,
      highlightLine,
      setStringVariables,
      setHighlightedStringVariables,
      setVariables,
      setHighlightedVariables,
      customSleep
    )
    setHighlightedStructureMethods([]);
    await highlightLine(20);
    await customSleep();

    await highlightLine(21);
    await customSleep();

    await highlightCallingLine(21);
    setHighlightedStructureMethods(['displayInfo']);
    await personDisplayInfoForCpp(
      initialStructure,
      highlightLine,
      setStringVariables,
      setHighlightedStringVariables,
      setVariables,
      setHighlightedVariables,
      customSleep,
      logMessage
    )
    setHighlightedStructureMethods([]);
    await highlightLine(21);
    await customSleep();

    await highlightLine(23);
    await customSleep()

    await focusEndLine(24);
    setIsRunning(false);
  };

  const runStructureType = async () => {
    if (language === 'C') {
      await runStructureTypeC();
    } else if (language === 'C++') {
      await runStructureTypeCpp();
    }
  };

  return {
    highlightedLine,
    callingLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    structureVariables,
    stringVariables,
    highlightedVariables,
    highlightedStructureVariables,
    highlightedStructureMethods,
    highlightedStringVariables,
    isMuted,
    setHighlightedLine,
    setCallingLine,
    setFocusedEndLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setStructureVariables,
    setStringVariables,
    setHighlightedVariables,
    setHighlightedStructureVariables,
    setHighlightedStructureMethods,
    setHighlightedStringVariables,
    setIsMuted,
    runStructureType
  };
};
