import { useState, useEffect, useRef } from 'react';
import explanations from './explanations';

export const useBasicString = (language, greeting) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [delay, setDelay] = useState(2000);
  const [logs, setLogs] = useState([]);
  const [arrayVariables, setArrayVariables] = useState({});
  const [variables, setVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedArrayVariables, setHighlightedArrayVariables] = useState([]);
  const [highlightedIndices, setHighlightedIndices] = useState([]);
  const [stringVariables, setStringVariables] = useState({}); //
  const [highlightedStringVariables, setHighlightedStringVariables] = useState([]); //
  const [highlightedStringIndices, setHighlightedStringIndices] = useState([]); //
  const [isMuted, setIsMuted] = useState(true);
  const isPausedRef = useRef(isPaused);
  const delayRef = useRef(delay);
  const isMutedRef = useRef(isMuted);
  const synth = window.speechSynthesis;

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setLogs([]);
    setHighlightedArrayVariables([]);
    setHighlightedIndices([]);
    setVariables({});
    setArrayVariables({});
    setHighlightedVariables([]);
    setStringVariables({});
    setHighlightedStringVariables([]);
    setHighlightedStringIndices([]);
  };

  const speak = (message) => {
    return new Promise((resolve) => {
      if (isMutedRef.current) {
        resolve();
        return;
      }
      const utterance = new SpeechSynthesisUtterance(message);
      utterance.onend = resolve;
      synth.speak(utterance);
    });
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async () => {
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runBasicStringPython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(1);
    setStringVariables({ greeting: { variable_name: 'greeting', value: greeting } });
    setHighlightedStringVariables(['greeting']);
    await customSleep();
    setHighlightedStringVariables([]);

    for (let i = 0; i < greeting.length; i++) {
      await highlightLine(2);
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i']);
      await customSleep();
      setHighlightedVariables([]);

      await highlightLine(3);
      setHighlightedStringIndices([{ stringName: 'greeting', index: i, iteratorName: 'i' }]);
      await logMessage(greeting[i]);
      await customSleep();
      setHighlightedStringIndices([]);
    }

    await focusEndLine(3);
    setIsRunning(false);
  };

  const runBasicStringJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(2);
    setStringVariables({ greeting: { variable_name: 'greeting', value: greeting } });
    setHighlightedStringVariables(['greeting']);
    await customSleep();
    setHighlightedStringVariables([]);

    for (let i = 0; i < greeting.length; i++) {
      await highlightLine(3);
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i']);
      await customSleep();
      setHighlightedVariables([]);

      await highlightLine(4);
      setHighlightedStringIndices([{ stringName: 'greeting', index: i, iteratorName: 'i' }]);
      await logMessage(greeting[i]);
      await customSleep();
      setHighlightedStringIndices([]);
    }

    await focusEndLine(4);
    setIsRunning(false);
  };

  const runBasicStringC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(4);
    setStringVariables({ greeting: { variable_name: 'greeting', value: greeting } });
    setHighlightedStringVariables(['greeting']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(5);
    let size = greeting.length;
    setVariables((vars) => ({ ...vars, size: { variable_name: 'size', value: size } }));
    setHighlightedVariables(['size']);
    await customSleep();
    setHighlightedVariables([]);

    for (let i = 0; i < greeting.length; i++) {
      await highlightLine(6);
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i', 'size']);
      await customSleep();
      setHighlightedVariables([]);

      await highlightLine(7);
      setHighlightedStringIndices([{ stringName: 'greeting', index: i, iteratorName: 'i' }]);
      await logMessage(greeting[i]);
      await customSleep();
      setHighlightedStringIndices([]);
    }

    await highlightLine(9);
    await customSleep();

    await focusEndLine(10);
    setIsRunning(false);
  };

  const runBasicStringCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(5);
    setStringVariables({ greeting: { variable_name: 'greeting', value: greeting } });
    setHighlightedStringVariables(['greeting']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(6);
    let size = greeting.length;
    setVariables((vars) => ({ ...vars, size: { variable_name: 'size', value: size } }));
    setHighlightedVariables(['size']);
    await customSleep();
    setHighlightedVariables([]);

    for (let i = 0; i < greeting.length; i++) {
      await highlightLine(7);
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i', 'size']);
      await customSleep();
      setHighlightedVariables([]);

      await highlightLine(8);
      setHighlightedStringIndices([{ stringName: 'greeting', index: i, iteratorName: 'i' }]);
      await logMessage(greeting[i]);
      await customSleep();
      setHighlightedStringIndices([]);
    }

    await highlightLine(10);
    await customSleep();

    await focusEndLine(11);
    setIsRunning(false);
  };

  const runBasicString = async () => {
    if (language === 'Python') {
      await runBasicStringPython();
    } else if (language === 'Java') {
      await runBasicStringJava();
    } else if (language === 'C') {
      await runBasicStringC();
    } else if (language === 'C++') {
      await runBasicStringCpp();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    arrayVariables,
    variables,
    stringVariables,
    highlightedVariables,
    highlightedArrayVariables,
    highlightedStringVariables,
    highlightedIndices,
    highlightedStringIndices,
    isMuted,
    setHighlightedLine,
    setFocusedEndLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setArrayVariables,
    setVariables,
    setStringVariables,
    setHighlightedVariables,
    setHighlightedStringVariables,
    setHighlightedArrayVariables,
    setHighlightedIndices,
    setHighlightedStringIndices,
    setIsMuted,
    runBasicString
  };
};
