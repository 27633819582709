import { useState, useEffect, useRef } from 'react';
import explanations from './explanations';

export const useSequenceTypes = (name, fruits, coordinates, numbers) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedArrayVariables, setHighlightedArrayVariables] = useState([]);
  const [highlightedIndices, setHighlightedIndices] = useState([]);
  const [isMuted, setIsMuted] = useState(true);
  const isMutedRef = useRef(isMuted);
  const synth = window.speechSynthesis;

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setVariables({});
    setArrayVariables({});
    setLogs([]);
    setHighlightedVariables([]);
    setHighlightedArrayVariables([]);
    setHighlightedIndices([]);
  };

  const speak = (message) => {
    return new Promise((resolve) => {
      if (isMutedRef.current) {
        resolve();
        return;
      }
      const utterance = new SpeechSynthesisUtterance(message);
      utterance.onend = resolve;
      synth.speak(utterance);
    });
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations['Python'][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async () => {
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runSequenceTypesPython = async () => {
    // String example
    await highlightLine(2);
    setVariables((vars) => ({ ...vars, name: { variable_name: 'name', value: name } }));
    setHighlightedVariables(['name']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(3);
    setHighlightedVariables(['name']);
    await logMessage(`String: ${name}`);
    await customSleep();
    setHighlightedVariables([]);

    // List example
    await highlightLine(6);
    setArrayVariables((vars) => ({ ...vars, fruits: { variable_name: 'fruits', value: fruits, width: '80px' } }));
    setHighlightedArrayVariables(['fruits']);
    await customSleep();
    setHighlightedArrayVariables([]);

    await highlightLine(7);
    setHighlightedArrayVariables(['fruits']);
    fruits.push('date');
    setArrayVariables((vars) => ({ ...vars, fruits: { variable_name: 'fruits', value: fruits, width: '80px' } }));
    await customSleep();
    setHighlightedArrayVariables([]);

    await highlightLine(8);
    setHighlightedArrayVariables(['fruits']);
    await logMessage(`List: ${fruits}`);
    await customSleep();
    setHighlightedArrayVariables([]);

    await highlightLine(9);
    setHighlightedIndices([{ arrayName: 'fruits', index: 0 }]);
    await logMessage(`First fruit: ${fruits[0]}`);
    await customSleep();
    setHighlightedIndices([]);

    // Tuple example
    await highlightLine(12);
    setArrayVariables((vars) => ({ ...vars, coordinates: { variable_name: 'coordinates', value: coordinates } }));
    setHighlightedArrayVariables(['coordinates']);
    await customSleep();
    setHighlightedArrayVariables([]);

    await highlightLine(13);
    setHighlightedArrayVariables(['coordinates']);
    await logMessage(`Tuple: ${coordinates}`);
    await customSleep();
    setHighlightedArrayVariables([]);

    await highlightLine(14);
    setHighlightedIndices([{ arrayName: 'coordinates', index: 1 }]);
    await logMessage(`Second coordinate: ${coordinates[1]}`);
    await customSleep();
    setHighlightedIndices([]);

    // Range example
    await highlightLine(17);
    setArrayVariables((vars) => ({ ...vars, numbers: { variable_name: 'numbers', value: numbers } }));
    setHighlightedArrayVariables(['numbers']);
    await customSleep();
    setHighlightedArrayVariables([]);

    await highlightLine(18);
    setHighlightedArrayVariables(['numbers']);
    await logMessage(`Range as list: ${numbers}`);
    await customSleep();
    setHighlightedArrayVariables([]);

    await focusEndLine(19);
    setIsRunning(false);
  };

  const runSequenceTypes = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await runSequenceTypesPython();

    setIsRunning(false);
  };

  return {
    highlightedLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    highlightedArrayVariables,
    highlightedIndices,
    isMuted,
    setHighlightedLine,
    setFocusedEndLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setHighlightedVariables,
    setHighlightedArrayVariables,
    setHighlightedIndices,
    setIsMuted,
    runSequenceTypes
  };
};
