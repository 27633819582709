const codeSamples = {
    Python: [
        "if __name__ == '__main__':",
        "    # Creating a dictionary",
        "    student = {",
        "        'name': 'Alice',",
        "        'age': 22,",
        "        'graduated': False",
        "    }",
        "  ",
        "    # Accessing values using keys",
        "    print('Name:', student['name'])",
        "    print('Age:', student['age'])",
        "    print('Graduated:', student['graduated'])",
        "  ",
        "    # Adding a new key-value pair",
        "    student['GPA'] = 3.8",
        "  ",
        "    # Updating an existing value",
        "    student['age'] = 23",
        "  ",
        "    # Removing a key-value pair",
        "    del student['graduated']",
        "  ",
        "    # Printing the updated dictionary",
        "    print('Updated student dictionary:')",
        "    for key, value in student.items():",
        "        print(f'{key}: {value}')"
    ],
    Java: [
        "import java.util.HashMap;",
        "import java.util.Map;",
        "  ",
        "public class Main {",
        "    public static void main(String[] args) {",
        "        Map<String, Object> student = new HashMap<>();",
        "        student.put('name', 'Alice');",
        "        student.put('age', 22);",
        "        student.put('graduated', false);",
        "  ",
        "        // Accessing values using keys",
        "        System.out.println('Name: ' + student.get('name'));",
        "        System.out.println('Age: ' + student.get('age'));",
        "        System.out.println('Graduated: ' + student.get('graduated'));",
        "  ",
        "        // Adding a new key-value pair",
        "        student.put('GPA', 3.8);",
        "  ",
        "        // Updating an existing value",
        "        student.put('age', 23);",
        "  ",
        "        // Removing a key-value pair",
        "        student.remove('graduated');",
        "  ",
        "        // Printing the updated dictionary",
        "        System.out.println('Updated student dictionary:');",
        "        for (Map.Entry<String, Object> entry : student.entrySet()) {",
        "            System.out.println(entry.getKey() + ': ' + entry.getValue());",
        "        }",
        "    }",
        "}"
    ]
};

export default codeSamples;
