import { useState, useEffect, useRef } from 'react';
import explanations from './explanations';
import {
  runStringEqualCheckAlgorithmPython,
  runStringEqualCheckAlgorithmJava,
  runStringEqualCheckAlgorithmC,
  runStringEqualCheckAlgorithmCpp,
} from './stringEqualCheckAlgorithms';

export const useStringEqualCheck = (language, string1, string2) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [delay, setDelay] = useState(2000);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [stringVariables, setStringVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedStringVariables, setHighlightedStringVariables] = useState([]);
  const [highlightedStringIndices, setHighlightedStringIndices] = useState([]);
  const [isMuted, setIsMuted] = useState(true);
  const isPausedRef = useRef(isPaused);
  const delayRef = useRef(delay);
  const isMutedRef = useRef(isMuted);
  const synth = window.speechSynthesis;

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setCallingLine(null);
    setLogs([]);
    setVariables({});
    setStringVariables({});
    setHighlightedVariables([]);
    setHighlightedStringVariables([]);
    setHighlightedStringIndices([]);
  };

  const speak = (message) => {
    return new Promise((resolve) => {
      if (isMutedRef.current) {
        resolve();
        return;
      }
      const utterance = new SpeechSynthesisUtterance(message);
      utterance.onend = resolve;
      synth.speak(utterance);
    });
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const highlightCallingLine = async (lineNumber) => {
    setCallingLine(lineNumber);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async () => {
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runStringEqualCheckPython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(11);
    setStringVariables({ string1: { variable_name: 'string1', value: string1 }});
    setHighlightedStringVariables(['string1']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(12);
    setStringVariables((vars) => ({ ...vars, string2: { variable_name: 'string2', value: string2 } }));
    setHighlightedStringVariables(['string2']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(14);
    await customSleep();

    await highlightCallingLine(14);
    let isEqual = await runStringEqualCheckAlgorithmPython(
      string1,
      string2,
      highlightLine,
      setVariables,
      setHighlightedVariables,
      setHighlightedStringIndices,
      customSleep
    );
    await highlightLine(14);
    await customSleep();

    if (isEqual) {
      await highlightLine(15);
      await logMessage("The strings are equal.");
      await customSleep();
    } else {
      await highlightLine(16);
      await customSleep();

      await highlightLine(17);
      await logMessage("The strings are not equal.");
      await customSleep();
    }

    await focusEndLine(17);
    setIsRunning(false);
  };

  const runStringEqualCheckJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(16);
    setStringVariables({ string1: { variable_name: 'string1', value: string1 }});
    setHighlightedStringVariables(['string1']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(17);
    setStringVariables((vars) => ({ ...vars, string2: { variable_name: 'string2', value: string2 } }));
    setHighlightedStringVariables(['string2']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(19);
    await customSleep();

    await highlightCallingLine(19);
    let isEqual = await runStringEqualCheckAlgorithmJava(
      string1,
      string2,
      highlightLine,
      setVariables,
      setHighlightedVariables,
      setHighlightedStringIndices,
      customSleep
    );
    await highlightLine(19);
    await customSleep();

    if (isEqual) {
      await highlightLine(20);
      await logMessage("The strings are equal.");
      await customSleep();
    } else {
      await highlightLine(21);
      await customSleep();

      await highlightLine(22);
      await logMessage("The strings are not equal.");
      await customSleep();
    }

    await focusEndLine(25);
    setIsRunning(false);
  };

  const runStringEqualCheckC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(18);
    setStringVariables({ string1: { variable_name: 'string1', value: string1 }});
    setHighlightedStringVariables(['string1']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(19);
    setStringVariables((vars) => ({ ...vars, string2: { variable_name: 'string2', value: string2 } }));
    setHighlightedStringVariables(['string2']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(21);
    await customSleep();

    await highlightCallingLine(21);
    let isEqual = await runStringEqualCheckAlgorithmC(
      string1,
      string2,
      highlightLine,
      setVariables,
      setHighlightedVariables,
      setHighlightedStringIndices,
      customSleep
    );
    await highlightLine(21);
    await customSleep();

    if (isEqual) {
      await highlightLine(22);
      await logMessage("The strings are equal.");
      await customSleep();
    } else {
      await highlightLine(23);
      await customSleep();

      await highlightLine(24);
      await logMessage("The strings are not equal.");
      await customSleep();
    }

    await highlightLine(26);
    await customSleep();

    await focusEndLine(27);
    setIsRunning(false);
  };

  const runStringEqualCheckCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(19);
    setStringVariables({ string1: { variable_name: 'string1', value: string1 }});
    setHighlightedStringVariables(['string1']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(20);
    setStringVariables((vars) => ({ ...vars, string2: { variable_name: 'string2', value: string2 } }));
    setHighlightedStringVariables(['string2']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(22);
    await customSleep();

    await highlightCallingLine(22);
    let isEqual = await runStringEqualCheckAlgorithmCpp(
      string1,
      string2,
      highlightLine,
      setVariables,
      setHighlightedVariables,
      setHighlightedStringIndices,
      customSleep
    );
    await highlightLine(22);
    await customSleep();

    if (isEqual) {
      await highlightLine(23);
      await logMessage("The strings are equal.");
      await customSleep();
    } else {
      await highlightLine(24);
      await customSleep();

      await highlightLine(25);
      await logMessage("The strings are not equal.");
      await customSleep();
    }

    await highlightLine(27);
    await customSleep();

    await focusEndLine(28);
    setIsRunning(false);
  };

  const runStringEqualCheck = async () => {
    if (language === 'Python') {
      await runStringEqualCheckPython();
    } else if (language === 'Java') {
      await runStringEqualCheckJava();
    } else if (language === 'C') {
      await runStringEqualCheckC();
    } else if (language === 'C++') {
      await runStringEqualCheckCpp();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    stringVariables,
    highlightedVariables,
    highlightedStringVariables,
    highlightedStringIndices,
    isMuted,
    setHighlightedLine,
    setFocusedEndLine,
    setCallingLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setStringVariables,
    setHighlightedVariables,
    setHighlightedStringVariables,
    setHighlightedStringIndices,
    setIsMuted,
    runStringEqualCheck,
  };
};
