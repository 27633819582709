import { useState, useEffect, useRef } from 'react';
import explanations from './explanations';
import {
  runPalindromeAlgorithmPython,
  runPalindromeAlgorithmJava,
  runPalindromeAlgorithmC,
  runPalindromeAlgorithmCpp,
} from './stringPalindromeAlgorithms';

export const useStringPalindrome = (language, string) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [delay, setDelay] = useState(2000);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [stringVariables, setStringVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedStringVariables, setHighlightedStringVariables] = useState([]);
  const [highlightedStringIndices, setHighlightedStringIndices] = useState([]);
  const [isMuted, setIsMuted] = useState(true);
  const isPausedRef = useRef(isPaused);
  const delayRef = useRef(delay);
  const isMutedRef = useRef(isMuted);
  const synth = window.speechSynthesis;

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setCallingLine(null);
    setLogs([]);
    setVariables({});
    setArrayVariables({});
    setStringVariables({});
    setHighlightedVariables([]);
    setHighlightedStringVariables([]);
    setHighlightedStringIndices([]);
  };

  const speak = (message) => {
    return new Promise((resolve) => {
      if (isMutedRef.current) {
        resolve();
        return;
      }
      const utterance = new SpeechSynthesisUtterance(message);
      utterance.onend = resolve;
      synth.speak(utterance);
    });
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const highlightCallingLine = async (lineNumber) => {
    setCallingLine(lineNumber);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async () => {
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runStringPalindromePython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(14);
    setStringVariables({ string: { variable_name: 'string', value: string } });
    setHighlightedStringVariables(['string']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(15);
    await customSleep();

    await highlightCallingLine(15);
    let isPalindrome = await runPalindromeAlgorithmPython(
        string,
        highlightLine,
        setVariables,
        setHighlightedVariables,
        setStringVariables,
        setHighlightedStringIndices,
        setHighlightedStringVariables,
        customSleep
    );
    await highlightLine(15);
    await customSleep();

    if (isPalindrome) {
        await highlightLine(16);
        await logMessage(`"${string}" is a palindrome.`);
        await customSleep();
    } else {
        await highlightLine(17);
        await customSleep();

        await highlightLine(18);
        await logMessage(`"${string}" is not a palindrome.`);
        await customSleep();
    }

    await focusEndLine(18);
    setIsRunning(false);
  };

  const runStringPalindromeJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(16);
    setStringVariables({ str: { variable_name: 'str', value: string } });
    setHighlightedStringVariables(['str']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(17);
    await customSleep();

    await highlightCallingLine(17);
    let isPalindrome = await runPalindromeAlgorithmJava(
        string,
        highlightLine,
        setVariables,
        setHighlightedVariables,
        setStringVariables,
        setHighlightedStringIndices,
        setHighlightedStringVariables,
        customSleep
    );
    await highlightLine(17);
    await customSleep();

    if (isPalindrome) {
        await highlightLine(18);
        await logMessage(`"${string}" is a palindrome.`);
        await customSleep();
    } else {
        await highlightLine(19);
        await customSleep();

        await highlightLine(20);
        await logMessage(`"${string}" is not a palindrome.`);
        await customSleep();
    }

    await focusEndLine(23);
    setIsRunning(false);
  };

  const runStringPalindromeC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(19);
    setStringVariables({ str: { variable_name: 'str', value: string } });
    setHighlightedStringVariables(['str']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(20);
    await customSleep();

    await highlightCallingLine(20);
    let isPalindrome = await runPalindromeAlgorithmC(
        string,
        highlightLine,
        setVariables,
        setHighlightedVariables,
        setStringVariables,
        setHighlightedStringIndices,
        setHighlightedStringVariables,
        customSleep
    );
    await highlightLine(20);
    await customSleep();

    if (isPalindrome) {
        await highlightLine(21);
        await logMessage(`"${string}" is a palindrome.`);
        await customSleep();
    } else {
        await highlightLine(22);
        await customSleep();

        await highlightLine(23);
        await logMessage(`"${string}" is not a palindrome.`);
        await customSleep();
    }

    await highlightLine(25);
    await customSleep();

    await focusEndLine(26);
    setIsRunning(false);
  };

  const runStringPalindromeCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(20);
    setStringVariables({ str: { variable_name: 'str', value: string } });
    setHighlightedStringVariables(['str']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(21);
    await customSleep();

    await highlightCallingLine(21);
    let isPalindrome = await runPalindromeAlgorithmCpp(
        string,
        highlightLine,
        setVariables,
        setHighlightedVariables,
        setStringVariables,
        setHighlightedStringIndices,
        setHighlightedStringVariables,
        customSleep
    );
    await highlightLine(21);
    await customSleep();

    if (isPalindrome) {
        await highlightLine(22);
        await logMessage(`"${string}" is a palindrome.`);
        await customSleep();
    } else {
        await highlightLine(23);
        await customSleep();

        await highlightLine(24);
        await logMessage(`"${string}" is not a palindrome.`);
        await customSleep();
    }

    await highlightLine(26);
    await customSleep();

    await focusEndLine(27);
    setIsRunning(false);
  };

  const runStringPalindrome = async () => {
    if (language === 'Python') {
      await runStringPalindromePython();
    } else if (language === 'Java') {
      await runStringPalindromeJava();
    } else if (language === 'C') {
      await runStringPalindromeC();
    } else if (language === 'C++') {
      await runStringPalindromeCpp();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    stringVariables,
    highlightedVariables,
    highlightedStringVariables,
    highlightedStringIndices,
    isMuted,
    setHighlightedLine,
    setFocusedEndLine,
    setCallingLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setStringVariables,
    setHighlightedVariables,
    setHighlightedStringVariables,
    setHighlightedStringIndices,
    setIsMuted,
    runStringPalindrome,
  };
};
