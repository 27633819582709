const codeSamples = {
    Python: [
        "if __name__ == '__main__':",
        "    # Creating a set",
        "    fruits = {'Apple', 'RaspBerries', 'Apple'}",
        "    print(fruits)",
        "  ",
        "    fruits.add('Orange')",
        "    print(fruits)",
        "  ",
        "    fruits.remove('Apple')",
        "    print(fruits)",
        "  ",
        "    print('Orange' in fruits)",
        "  ",
        "    berry_fruits = {'BlueBerries', 'RaspBerries'}",
        "  ",
        "    # Union",
        "    union_set = fruits.union(berry_fruits)",
        "    print(union_set)",
        "  ",
        "    # Intersection",
        "    intersection_set = fruits.intersection(berry_fruits)",
        "    print(intersection_set)",
        "  ",
        "    # Difference",
        "    difference_set = fruits.difference(berry_fruits)",
        "    print(difference_set)"
    ],
    Java: [
        "import java.util.HashSet;",
        "import java.util.Set;",
        "  ",
        "public class Main {",
        "    public static void main(String[] args) {",
        "        Set<String> fruits = new HashSet<>();",
        "        fruits.add('Apple');",
        "        fruits.add('RaspBerries');",
        "        fruits.add('Apple');",
        "        System.out.println(fruits);",
        "  ",
        "        // Adding an element to the set",
        "        fruits.add('Orange');",
        "        System.out.println(fruits);",
        "  ",
        "        // Removing an element from the set",
        "        fruits.remove('Apple');",
        "        System.out.println(fruits);",
        "  ",
        "        // Checking if an element is in the set",
        "        System.out.println(fruits.contains('Orange'));",
        "  ",
        "        // Creating another set for berry fruits",
        "        Set<String> berryFruits = new HashSet<>();",
        "        berryFruits.add('BlueBerries');",
        "        berryFruits.add('RaspBerries');",
        "  ",
        "        // Union of two sets",
        "        Set<String> unionSet = new HashSet<>(fruits);",
        "        unionSet.addAll(berryFruits);",
        "        System.out.println(unionSet);",
        "  ",
        "        // Intersection of two sets",
        "        Set<String> intersectionSet = new HashSet<>(fruits);",
        "        intersectionSet.retainAll(berryFruits);",
        "        System.out.println(intersectionSet);",
        "  ",
        "        // Difference of two sets",
        "        Set<String> differenceSet = new HashSet<>(fruits);",
        "        differenceSet.removeAll(berryFruits);",
        "        System.out.println(differenceSet);",
        "    }",
        "}"
    ]
};

export default codeSamples;
