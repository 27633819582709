import React, { useState, useEffect } from 'react';

const ContactUs = () => {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');

  const handleRatingChange = (value) => setRating(value);
  const handleFeedbackChange = (event) => setFeedback(event.target.value);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Submit logic here
    console.log("Rating:", rating);
    console.log("Feedback:", feedback);
  };

  useEffect(() => {
    // Set an SEO-friendly page title
    document.title = `Contact Us | Coding Canvas`;

    // Set meta description for SEO
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", `Explore interactive tutorials, code samples, and exercises to learn programming visually on Coding Canvas.`);
    }
  });

  return (
    <main className="container">
        {/* Contact Us Section */}
        <section className="text-center mb-5">
            <h1 className="mb-3">Get in Touch</h1>
            <p>We’d love to hear from you! Reach out for support, feedback, or inquiries.</p>
        </section>

        <div className="row justify-content-center">
            {/* Contact Form Card */}
            <div className="col-lg-6 mb-4 d-flex">
            <div className="card shadow-lg border-2 rounded-3 w-100 d-flex flex-column">
                <div className="card-body p-4 d-flex flex-column">
                <h2 className="card-title text-center mb-4">Contact Us</h2>
                <form onSubmit={handleSubmit} className="flex-grow-1 d-flex flex-column">
                    <div className="mb-3">
                    <label htmlFor="email" className="form-label fw-bold">Email</label>
                    <input
                        type="email"
                        style={{backgroundColor: 'white'}}
                        className="form-control rounded-pill shadow-sm"
                        id="email"
                        placeholder="Enter your email"
                        required
                    />
                    </div>

                    <div className="mb-3 flex-grow-1">
                    <label htmlFor="message" className="form-label fw-bold">Message</label>
                    <textarea
                        style={{backgroundColor: 'white'}}
                        className="form-control rounded-3 shadow-sm"
                        id="message"
                        rows="4"
                        placeholder="Type your message here..."
                        required
                    />
                    </div>

                    <button type="submit" className="btn btn-dark w-100 rounded-pill shadow-sm mt-auto">Send Message</button>
                </form>
                </div>
            </div>
            </div>

            {/* Feedback Card */}
            <div className="col-lg-6 mb-4 d-flex">
            <div className="card shadow-lg border-2 rounded-3 w-100 d-flex flex-column">
                <div className="card-body p-4 d-flex flex-column">
                <h2 className="card-title text-center mb-4">Your Feedback</h2>
                <p className="text-center text-muted">Rate your experience with us:</p>

                {/* Rating */}
                <div className="d-flex justify-content-center mb-4">
                    {[1, 2, 3, 4, 5].map((star) => (
                    <button
                        type="button"
                        key={star}
                        onClick={() => handleRatingChange(star)}
                        className={`btn ${rating >= star ? 'btn-dark' : 'btn-outline-dark'} mx-1 rounded-circle`}
                        style={{ width: '40px', height: '40px' }}
                    >
                        {star}
                        {rating >= star ? 
                            <i 
                                className="fa fa-star"
                                aria-hidden="true" 
                                style={{color: '#212529', paddingTop: '10px'}}
                            ></i> : 
                            <i 
                                className="fa-regular fa-star"
                                style={{color: '#212529', paddingTop: '10px'}}
                            ></i>}
                    </button>
                    ))}
                </div>

                {/* Feedback Text */}
                <form onSubmit={handleSubmit} className="flex-grow-1 d-flex flex-column">
                    <div className="mb-3 flex-grow-1" style={{paddingTop: '10px'}}>
                        <textarea
                            style={{backgroundColor: 'white'}}
                            className="form-control rounded-3 shadow-sm"
                            placeholder="Leave additional feedback here..."
                            value={feedback}
                            onChange={handleFeedbackChange}
                            rows="4"
                        />
                    </div>

                    <button type="submit" className="btn btn-dark w-100 rounded-pill shadow-sm mt-auto">Submit Feedback</button>
                </form>
                </div>
            </div>
            </div>
        </div>
    </main>

  );
};

export default ContactUs;
