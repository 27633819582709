import { useState, useEffect, useRef } from 'react';
import explanations from './explanations';

export const useStringInterning = (language) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [delay, setDelay] = useState(2000);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [stringVariables, setStringVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedStringVariables, setHighlightedStringVariables] = useState([]);
  const [highlightedStringIndices, setHighlightedStringIndices] = useState([]);
  const [isMuted, setIsMuted] = useState(true);
  const isPausedRef = useRef(isPaused);
  const delayRef = useRef(delay);
  const isMutedRef = useRef(isMuted);
  const synth = window.speechSynthesis;

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setCallingLine(null);
    setLogs([]);
    setVariables({});
    setStringVariables({});
    setHighlightedVariables([]);
    setHighlightedStringVariables([]);
    setHighlightedStringIndices([]);
  };

  const speak = (message) => {
    return new Promise((resolve) => {
      if (isMutedRef.current) {
        resolve();
        return;
      }
      const utterance = new SpeechSynthesisUtterance(message);
      utterance.onend = resolve;
      synth.speak(utterance);
    });
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async () => {
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runStringInterningPython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(1);
    setStringVariables({ s1: { variable_name: 's1', value: 'hello' } });
    setHighlightedStringVariables(['s1']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(2);
    setStringVariables((prev) => ({ ...prev, s2: { variable_name: 's2', value: 'world' } }));
    setHighlightedStringVariables(['s2']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(3);
    setStringVariables((prev) => ({ ...prev, s3: { variable_name: 's3', value: 'hello' } }));
    setHighlightedStringVariables(['s3']);
    await customSleep();
    setHighlightedStringVariables([]);

    await focusEndLine(3);
    setIsRunning(false);
  };

  const runStringInterningJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(2);
    setStringVariables({ s1: { variable_name: 's1', value: 'hello' } });
    setHighlightedStringVariables(['s1']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(3);
    setStringVariables((prev) => ({ ...prev, s2: { variable_name: 's2', value: 'world' } }));
    setHighlightedStringVariables(['s2']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(4);
    setStringVariables((prev) => ({ ...prev, s3: { variable_name: 's3', value: 'hello' } }));
    setHighlightedStringVariables(['s3']);
    await customSleep();
    setHighlightedStringVariables([]);

    await focusEndLine(4);
    setIsRunning(false);
  };

  const runStringInterning = async () => {
    if (language === 'Python') {
      await runStringInterningPython();
    } else if (language === 'Java') {
      await runStringInterningJava();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    stringVariables,
    highlightedVariables,
    highlightedStringVariables,
    highlightedStringIndices,
    isMuted,
    setHighlightedLine,
    setFocusedEndLine,
    setCallingLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setStringVariables,
    setHighlightedVariables,
    setHighlightedStringVariables,
    setHighlightedStringIndices,
    setIsMuted,
    runStringInterning,
  };
};
