import React from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvasForBinaryTree';
import AlgorithmDescription from '../AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';

import { useBinaryTree } from './useBinaryTree';
import codeSamples from './codeSamples';

const BinaryTreeLevelorderTraversal = ({ language, subTopicId }) => {
  const data = [20, 15, 30, 10, 17, 25, 40];

  const {
    highlightedLine,
    callingLine,
    secondCallingLine,
    highlightedMultipleLines,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    rootVariables,
    nodeVariables,
    singleNodeVariable,
    stackVariables,
    highlightedVariables,
    highlightedRootVariables,
    highlightedNodeVariables,
    highlightedSingleNodeVariables,
    highlightSingleNodeLeftPart,
    highlightSingleNodeDataPart,
    highlightSingleNodeRightPart,
    highlightLeftPart,
    highlightDataPart,
    highlightRightPart,
    highlightNextAddressPart,
    highlightNodeAddress,
    highlightedStackVariables,
    highlightedStackIndex,
    strikeThroughStackIndex,
    isMuted,
    setDelay,
    setIsPaused,
    setIsMuted,
    runBinaryTree,
  } = useBinaryTree(language, data);

  const codeLines = codeSamples[language] || [];

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeLines}
            highlightedLine={highlightedLine}
            highlightedMultipleLines={highlightedMultipleLines}
            focusedEndLine={focusedEndLine}
            topic={"Level Order Traversal"}
            callingLine={callingLine}
            secondCallingLine={secondCallingLine}
            subTopicId={subTopicId}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runBinaryTree}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              variables={variables}
              highlightedVariables={highlightedVariables}
              rootVariables={rootVariables}
              highlightedRootVariables={highlightedRootVariables}
              singleNodeVariable={singleNodeVariable}
              nodeVariables={nodeVariables}
              highlightedNodeVariables={highlightedNodeVariables}
              highlightedSingleNodeVariables={highlightedSingleNodeVariables}
              highlightSingleNodeLeftPart={highlightSingleNodeLeftPart}
              highlightSingleNodeDataPart={highlightSingleNodeDataPart}
              highlightSingleNodeRightPart={highlightSingleNodeRightPart}
              highlightLeftPart={highlightLeftPart}
              highlightDataPart={highlightDataPart}
              highlightRightPart={highlightRightPart}
              highlightNextAddressPart={highlightNextAddressPart}
              highlightNodeAddress={highlightNodeAddress}
              stackVariables={stackVariables}
              highlightedStackVariables={highlightedStackVariables}
              highlightedStackIndex={highlightedStackIndex}
              strikeThroughStackIndex={strikeThroughStackIndex}
              language={language}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
          <AlgorithmDescription />
      </div>
    </div>
  );
};

export default BinaryTreeLevelorderTraversal;
