const codeSamples = {
    Python: [
      "class Graph:",
      "    def __init__(self):",
      "        self.graph = {}",
      "  ",
      "    def add_edge(self, vertex, neighbor, directed=False):",
      "        if vertex not in self.graph:",
      "            self.graph[vertex] = []",
      "        if neighbor not in self.graph:",
      "            self.graph[neighbor] = []",
      "  ",
      "        self.graph[vertex].append(neighbor)",
      "        if not directed:",
      "            self.graph[neighbor].append(vertex)",
      "  ",
      "  ",
      "if __name__ == '__main__':",
      "    graph = Graph()",
      "    graph.add_edge(10, 20)",
      "    graph.add_edge(10, 25)",
      "    graph.add_edge(20, 42)",
      "    graph.add_edge(25, 42)",
      "    graph.add_edge(25, 50, directed=True)"
    ],
    Java: [
      "import java.util.*;",
      "  ",
      "class Graph {",
      "    private Map<Integer, List<Integer>> graph;",
      "  ",
      "    public Graph() {",
      "        graph = new HashMap<>();",
      "    }",
      "  ",
      "    public void addEdge(int vertex, int neighbor, boolean directed) {",
      "        graph.putIfAbsent(vertex, new ArrayList<>());",
      "        graph.putIfAbsent(neighbor, new ArrayList<>());",
      "        graph.get(vertex).add(neighbor);",
      "        if (!directed) {",
      "            graph.get(neighbor).add(vertex);",
      "        }",
      "    }",
      "  ",
      "  ",
      "public class Main {",
      "    public static void main(String[] args) {",
      "        Graph graph = new Graph();",
      "        graph.addEdge(10, 20, false);",
      "        graph.addEdge(10, 25, false);",
      "        graph.addEdge(20, 42, false);",
      "        graph.addEdge(25, 42, false);",
      "        graph.addEdge(25, 50, true);",
      "    }",
      "}"
    ],
    C: [
      "#include <stdio.h>",
      "#include <stdlib.h>",
      "#include <stdbool.h>",
      "  ",
      "#define MAX_VERTICES 6  // Maximum number of vertices",
      "  ",
      "typedef struct Node {",
      "    int vertex;",
      "    struct Node* next;",
      "} Node;",
      "  ",
      "typedef struct Graph {",
      "    Node* adjLists[MAX_VERTICES];",
      "} Graph;",
      "  ",
      "Graph* createGraph() {",
      "    Graph* graph = (Graph*)malloc(sizeof(Graph));",
      "    for (int i = 0; i < MAX_VERTICES; i++) {",
      "        graph->adjLists[i] = NULL;",
      "    }",
      "    return graph;",
      "}",
      "  ",
      "Node* createNode(int v) {",
      "    Node* newNode = (Node*)malloc(sizeof(Node));",
      "    newNode->vertex = v;",
      "    newNode->next = NULL;",
      "    return newNode;",
      "}",
      "  ",
      "void addEdge(Graph* graph, int vertex, int neighbor, bool directed) {",
      "    Node* newNode = createNode(neighbor);",
      "    newNode->next = graph->adjLists[vertex];",
      "    graph->adjLists[vertex] = newNode;",
      "  ",
      "    if (!directed) {",
      "        newNode = createNode(vertex);",
      "        newNode->next = graph->adjLists[neighbor];",
      "        graph->adjLists[neighbor] = newNode;",
      "    }",
      "}",
      "  ",
      "  ",
      "int main() {",
      "    Graph* graph = createGraph();",
      "  ",
      "    addEdge(graph, 1, 2, false);",
      "    addEdge(graph, 1, 3, false);",
      "    addEdge(graph, 2, 4, false);",
      "    addEdge(graph, 3, 4, false);",
      "    addEdge(graph, 3, 5, true);",
      "  ",
      "    return 0;",
      "}"
    ],
    "C++": [
      "#include <iostream>",
      "#include <unordered_map>",
      "#include <vector>",
      "  ",
      "using namespace std;",
      "  ",
      "class Graph {",
      "private:",
      "    unordered_map<int, vector<int>> graph;",
      "  ",
      "public:",
      "    // Add an edge to the graph (directed or undirected)",
      "    void addEdge(int vertex, int neighbor, bool directed = false) {",
      "        if (graph.find(vertex) == graph.end()) {",
      "            graph[vertex] = vector<int>();",
      "        }",
      "        if (graph.find(neighbor) == graph.end()) {",
      "            graph[neighbor] = vector<int>();",
      "        }",
      "        graph[vertex].push_back(neighbor);",
      "        if (!directed) {",
      "            graph[neighbor].push_back(vertex);",
      "        }",
      "    }",
      "};",
      "  ",
      "  ",
      "int main() {",
      "    Graph graph;",
      "    graph.addEdge(10, 20);",
      "    graph.addEdge(10, 25);",
      "    graph.addEdge(20, 42);",
      "    graph.addEdge(25, 42);",
      "    graph.addEdge(25, 50, true);  // Directed edge",
      "  ",
      "    return 0;",
      "}"
    ]
  };
  
  export default codeSamples;
  