const explanations = {
    Python: {
        3: "Creating a set named 'fruits'",
        4: "Printing the elements of the 'fruits' set.",
        6: "Adding an element to the 'fruits' set.",
        7: "Printing the updated 'fruits' set",
        9: "Removing an element from the 'fruits' set.",
        10: "Printing the updated 'fruits' set",
        12: "Checking if an element is present in the 'fruits' set and printing the result.",
        14: "Creating another set named 'berry_fruits'.",
        17: "Performing union of 'fruits' with 'berry_fruits' and storing the result in 'union_set'.",
        18: "Printing the 'union_set' which contains all elements from both 'fruits' and 'berry_fruits'.",
        21: "Performing intersection of 'fruits' with 'berry_fruits' and storing the result in 'intersection_set'.",
        22: "Printing the 'intersection_set' which contains elements common to both 'fruits' and 'berry_fruits'.",
        25: "Performing difference of 'fruits' from 'berry_fruits' and storing the result in 'difference_set'.",
        26: "Printing the 'difference_set' which contains elements in 'fruits' but not in 'berry_fruits'.",
    },
    Java: {
        6: "Creating a HashSet named 'fruits'.",
        7: "Adding item to the 'fruits' set.",
        8: "Adding item to the 'fruits' set.",
        9: "Attempting to add the existing item again, but since sets do not allow duplicates, it will not be added.",
        10: "Printing the elements of the 'fruits' set.",
        13: "Adding item to the 'fruits' set.",
        14: "Printing the updated 'fruits' set.",
        17: "Removing item from the 'fruits' set.",
        18: "Printing the updated 'fruits' set.",
        21: "Checking if 'Orange' is present in the 'fruits' set and printing the result.",
        24: "Creating another HashSet named 'berryFruits'",
        25: "Adding 'BlueBerries' to it.",
        26: "Adding 'RaspBerries' to it.",
        29: "Create a new HashSet named 'unionSet' that contains all elements from the 'fruits' collection.",
        30: "Performing union of 'fruits' with 'berryFruits' and storing the result in 'unionSet'.",
        31: "Printing the 'unionSet' which contains all elements from both 'fruits' and 'berryFruits'.",
        34: "Create a new HashSet named 'intersectionSet' that contains all elements from the 'fruits' collection.",
        35: "Performing intersection of 'fruits' with 'berryFruits' and storing the result in 'intersectionSet'.",
        36: "Printing the 'intersectionSet' which contains elements common to both 'fruits' and 'berryFruits'.",
        39: "Create a new HashSet named 'differenceSet' that contains all elements from the 'fruits' collection.",
        40: "Performing difference of 'fruits' from 'berryFruits' and storing the result in 'differenceSet'.",
        41: "Printing the 'differenceSet' which contains elements in 'fruits' but not in 'berryFruits'."
    }
};

export default explanations;
