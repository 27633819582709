import React from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from '../AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';

import { useStringConcatenation } from './useStringConcatenation';
import codeSamples from './codeSamples';

const StringConcatenation = ({ language, subTopicId }) => {
    const str1 = "Code";
    const str2 = "Sleep"

    const {
        highlightedLine,
        focusedEndLine,
        isRunning,
        isPaused,
        delay,
        logs,
        variables,
        stringVariables,
        highlightedVariables,
        highlightedStringVariables,
        highlightedStringIndices,
        isMuted,
        setDelay,
        setIsPaused,
        setIsMuted,
        runStringConcatenation,
    } = useStringConcatenation(language, str1, str2);

    return (
        <div className="container-fluid main-container">
            <div className="content-area d-flex">
                <div className="col-lg-6">
                <CodeDisplay
                    codeLines={codeSamples[language] || []}
                    highlightedLine={highlightedLine}
                    focusedEndLine={focusedEndLine}
                    topic={"String Concatenation"}
                    subTopicId={subTopicId}
                />
                </div>
                <div className="col-lg-6">
                <div className="visualize-card shadow-lg p-3">
                    <h3 className="section-title">Canvas</h3>
                    <Controls
                        isRunning={isRunning}
                        isPaused={isPaused}
                        delay={delay}
                        setDelay={setDelay}
                        runVisualization={runStringConcatenation}
                        setIsPaused={setIsPaused}
                        isMuted={isMuted}
                        setIsMuted={setIsMuted}
                    />
                    <VisualizationCanvas
                        variables={variables}
                        stringVariables={stringVariables}
                        highlightedVariables={highlightedVariables}
                        highlightedStringVariables={highlightedStringVariables}
                        highlightedStringIndices={highlightedStringIndices}
                    />
                    <LoggerDrawer logs={logs} />
                </div>
                </div>
            </div>
            <div className="col-lg-12 mt-4">
                <AlgorithmDescription />
            </div>
        </div>
    );
};

export default StringConcatenation;
