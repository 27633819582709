import React, { useEffect, useState } from 'react';
import axios from 'axios';

const MainContent = ({ onSelectLanguage }) => {
  const [languages, setLanguages] = useState([]);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Set SEO-friendly page title
    document.title = `Coding Canvas`;

    // Set meta description for SEO
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", `Explore interactive tutorials, code samples, and exercises to learn programming visually on Coding Canvas.`);
    }

    // Fetch language data from the API
    const fetchLanguages = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/languages/`);
        setLanguages(response.data);
      } catch (error) {
        console.error("Error fetching language data:", error);
      }
    };

    fetchLanguages();
  }, []);

  return (
    <main className="container my-5">
      <section className="text-center mb-5">
        <h2>What is CodingCanvas?</h2>
        <p>CodingCanvas is an interactive platform that helps students visualize complex programming concepts with ease. Learn through code visualizations, animations, and step-by-step guidance in Python, Java, C, and C++.</p>
      </section>

      <section className="video-section text-center mb-5">
        <h2>How to Use CodingCanvas</h2>
        <div className="video-wrapper">
          <video controls width="100%" poster="/Cover-Photo.png">
            <source src="https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/720/Big_Buck_Bunny_720_10s_1MB.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </section>

      <section className="row text-center mb-5">
        <div className="container-fluid">
          <div className="row no-gutters d-flex justify-content-center">
            <div className="col-md-4 mb-4 d-flex">
              <div className="card p-4 h-100">
                <h3>Interactive Learning</h3>
                <p>
                  Watch your code come to life through visualizations, making it easier to
                  grasp the toughest topics.
                </p>
              </div>
            </div>
            <div className="col-md-4 mb-4 d-flex">
              <div className="card p-4 h-100">
                <h3>Multi-Language Support</h3>
                <p>
                  Explore visualizations in Python, Java, C, and C++ with line-by-line
                  explanations and interactive exercises.
                </p>
              </div>
            </div>
            <div className="col-md-4 mb-4 d-flex">
              <div className="card p-4 h-100">
                <h3>Personalized Experience</h3>
                <p>
                  Track your progress and customize learning paths to improve your understanding
                  of programming concepts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="row text-center mb-5">
        <div className="container-fluid">
          <div className="row no-gutters d-flex justify-content-center">
            {languages.map((language) => (
              <div key={language.id} className="col-md-3 mb-3 d-flex">
                <div className="card p-3 h-100 d-flex flex-column justify-content-between">
                  <div className="text-center">
                    <img
                      src={`/${language.logo}`}
                      alt={`${language.display_name} logo`}
                      style={{ width: '60px', height: '60px', marginBottom: '15px' }}
                    />
                    <h3>{language.display_name}</h3>
                    <p style={{ fontSize: '15px' }}>{language.description}</p>
                  </div>
                  <section className="text-center mt-auto">
                    <a
                      href="#"
                      className="btn btn-lg get-started-btn"
                      onClick={(e) => { e.preventDefault(); onSelectLanguage(language.name); }}
                    >
                      Get Started
                    </a>
                  </section>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </main>
  );
};

export default MainContent;
