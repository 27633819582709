const codeSamples = {
    Python: [
        "def merge_sort(arr):",
        "    if len(arr) > 1:",
        "        mid = len(arr) // 2",
        "        L = arr[:mid]",
        "        R = arr[mid:]",
        "  ",
        "        merge_sort(L)",
        "        merge_sort(R)",
        "  ",
        "        i = j = k = 0",
        "  ",
        "        while i < len(L) and j < len(R):",
        "            if L[i] < R[j]:",
        "                arr[k] = L[i]",
        "                i += 1",
        "            else:",
        "                arr[k] = R[j]",
        "                j += 1",
        "            k += 1",
        "  ",
        "        while i < len(L):",
        "            arr[k] = L[i]",
        "            i += 1",
        "            k += 1",
        "  ",
        "        while j < len(R):",
        "            arr[k] = R[j]",
        "            j += 1",
        "            k += 1",
        "  ",
        "if __name__ == '__main__':",
        "    arr = [12, 11, 13, 5, 6]",
        "    merge_sort(arr)",
        "    print(f'Sorted array is: {arr}')"
    ],
    Java: [
        "import java.util.Arrays;",
        "  ",
        "public class MergeSort {",
        "  ",
        "    public static void mergeSort(int[] arr) {",
        "        if (arr.length > 1) {",
        "            int mid = arr.length / 2;",
        "  ",
        "            int[] L = new int[mid];",
        "            int[] R = new int[arr.length - mid];",
        "  ",
        "            System.arraycopy(arr, 0, L, 0, mid);",
        "            System.arraycopy(arr, mid, R, 0, arr.length - mid);",
        "  ",
        "            mergeSort(L);",
        "            mergeSort(R);",
        "  ",
        "            int i = 0, j = 0, k = 0;",
        "            while (i < L.length && j < R.length) {",
        "                if (L[i] < R[j]) {",
        "                    arr[k++] = L[i++];",
        "                } else {",
        "                    arr[k++] = R[j++];",
        "                }",
        "            }",
        "  ",
        "            while (i < L.length) {",
        "                arr[k++] = L[i++];",
        "            }",
        "  ",
        "            while (j < R.length) {",
        "                arr[k++] = R[j++];",
        "            }",
        "        }",
        "    }",
        "  ",
        "    public static void main(String[] args) {",
        "        int[] arr = {12, 11, 13, 5, 6};",
        "        mergeSort(arr);",
        "        System.out.println(\"Sorted array is: \" + Arrays.toString(arr));",
        "    }",
        "}"
    ],
    C: [
        "#include <stdio.h>",
        "#include <string.h>",
        "#include <stdlib.h>",
        "  ",
        "void merge_sort(int* arr, int n) {",
        "    if (n > 1) {",
        "        int mid = n / 2;",
        "  ",
        "        int* L = (int*)malloc(mid * sizeof(int));",
        "        int* R = (int*)malloc((n - mid) * sizeof(int));",
        "  ",
        "        memcpy(L, arr, mid * sizeof(int));",
        "        memcpy(R, arr + mid, (n - mid) * sizeof(int));",
        "  ",
        "        merge_sort(L, mid);",
        "        merge_sort(R, n - mid);",
        "  ",
        "        int i = 0, j = 0, k = 0;",
        "  ",
        "        while (i < mid && j < (n - mid)) {",
        "            if (L[i] < R[j]) {",
        "                arr[k++] = L[i++];",
        "            } else {",
        "                arr[k++] = R[j++];",
        "            }",
        "        }",
        "  ",
        "        while (i < mid) {",
        "            arr[k++] = L[i++];",
        "        }",
        "  ",
        "        while (j < (n - mid)) {",
        "            arr[k++] = R[j++];",
        "        }",
        "  ",
        "        free(L);",
        "        free(R);",
        "    }",
        "}",
        "  ",
        "int main() {",
        "    int arr[] = {12, 11, 13, 5, 6};",
        "    int n = sizeof(arr) / sizeof(arr[0]);",
        "  ",
        "    merge_sort(arr, n);",
        "  ",
        "    printf(\"Sorted array is: \");",
        "    for (int itr = 0; itr < n; itr++) {",
        "        printf(\"%d \", arr[itr]);",
        "    }",
        "    return 0;",
        "}"
    ],
    "C++": [
        "#include <iostream>",
        "#include <vector>",
        "  ",
        "void merge_sort(std::vector<int>& arr) {",
        "    if (arr.size() > 1) {",
        "        int mid = arr.size() / 2;",
        "        std::vector<int> L(arr.begin(), arr.begin() + mid);",
        "        std::vector<int> R(arr.begin() + mid, arr.end());",
        "  ",
        "        merge_sort(L);",
        "        merge_sort(R);",
        "  ",
        "        int i = 0, j = 0, k = 0;",
        "  ",
        "        while (i < L.size() && j < R.size()) {",
        "            if (L[i] < R[j]) {",
        "                arr[k++] = L[i++];",
        "            } else {",
        "                arr[k++] = R[j++];",
        "            }",
        "        }",
        "  ",
        "        while (i < L.size()) {",
        "            arr[k++] = L[i++];",
        "        }",
        "  ",
        "        while (j < R.size()) {",
        "            arr[k++] = R[j++];",
        "        }",
        "    }",
        "}",
        "  ",
        "int main() {",
        "    std::vector<int> arr = {12, 11, 13, 5, 6};",
        "    merge_sort(arr);",
        "    std::cout << \"Sorted array is: \";",
        "    for (int itr = 0; itr < arr.size(); itr++) {",
        "        std::cout << arr[itr] << \" \";",
        "    }",
        "    return 0;",
        "}"
    ]

  };
  
  export default codeSamples;
  