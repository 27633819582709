import React from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvasForLinkedList';
import AlgorithmDescription from '../AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';

import { useLinkedListTraversal } from './useLinkedListTraversal';
import codeSamples from './codeSamples';

const LinkedListTraversal = ({ language, subTopicId }) => {
  const {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    nodeVariables,
    headVariables,
    highlightedVariables,
    highlightedHeadVariables,
    highlightedNodeVariables,
    highlightDataPart,
    highlightNextAddressPart,
    highlightNodeAddress,
    isMuted,
    setDelay,
    setIsPaused,
    setIsMuted,
    runLinkedList
  } = useLinkedListTraversal(language);

  const codeLines = codeSamples[language] || [];

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeLines}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={"Linked List Traversal"}
            callingLine={callingLine}
            subTopicId={subTopicId}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runLinkedList}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              variables={variables}
              highlightedVariables={highlightedVariables}
              nodeVariables={nodeVariables}
              highlightedNodeVariables={highlightedNodeVariables}
              highlightDataPart={highlightDataPart}
              highlightNextAddressPart={highlightNextAddressPart}
              highlightNodeAddress={highlightNodeAddress}
              headVariables={headVariables}
              highlightedHeadVariables={highlightedHeadVariables}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
          <AlgorithmDescription />
      </div>
    </div>
  );
};

export default LinkedListTraversal;
